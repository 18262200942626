/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface Agreement {
    /** סוג הסכם */
    agreement_form_type: AgreementFormTypeEnum
    /** שפת הסכם */
    agreement_language: AgreementLanguageEnum
    /** תוכן */
    content: string
    /** @format date-time */
    created_at: string
    id: number
    /** @format date-time */
    updated_at: string
}

/**
 * סוג הסכם
 * * `terms` - תנאי שימוש
 * * `accessibility` - נגישות
 * * `terms-app` - תנאי שימוש באפליקציה
 */
export enum AgreementFormTypeEnum {
    Accessibility = 'accessibility',
    Terms = 'terms',
    TermsApp = 'terms-app',
}

/**
 * שפת הסכם
 * * `hebrew` - עברית
 * * `english` - אנגלית
 */
export enum AgreementLanguageEnum {
    English = 'english',
    Hebrew = 'hebrew',
}

export interface AgreementRequest {
    /** סוג הסכם */
    agreement_form_type: AgreementFormTypeEnum
    /** שפת הסכם */
    agreement_language: AgreementLanguageEnum
    /**
     * תוכן
     * @minLength 1
     */
    content: string
}

export interface ApiAgreementsListParams {
    /**
     * סוג הסכם
     * * `terms` - תנאי שימוש
     * * `accessibility` - נגישות
     * * `terms-app` - תנאי שימוש באפליקציה
     */
    agreement_form_type?: AgreementFormTypeEnum
    /**
     * שפת הסכם
     * * `hebrew` - עברית
     * * `english` - אנגלית
     */
    agreement_language?: AgreementLanguageEnum
    /** A page number within the paginated result set. */
    page?: number
}

/**
 * סוג הסכם
 * * `terms` - תנאי שימוש
 * * `accessibility` - נגישות
 * * `terms-app` - תנאי שימוש באפליקציה
 */
export enum ApiAgreementsListParams1AgreementFormTypeEnum {
    Accessibility = 'accessibility',
    Terms = 'terms',
    TermsApp = 'terms-app',
}

/**
 * שפת הסכם
 * * `hebrew` - עברית
 * * `english` - אנגלית
 */
export enum ApiAgreementsListParams1AgreementLanguageEnum {
    English = 'english',
    Hebrew = 'hebrew',
}

export interface ApiBranchManagersListParams {
    /** @pattern ^[0-9]+$ */
    branchId: string
    /** A page number within the paginated result set. */
    page?: number
}

export interface ApiBranchOperatorsListParams {
    /** @pattern ^[0-9]+$ */
    branchId: string
    /** A page number within the paginated result set. */
    page?: number
}

export interface ApiCancelRenewalRetrieveParams {
    /** The ID of the Subscription to cancel the renewal */
    id: number
}

export interface ApiCountriesListParams {
    /** A page number within the paginated result set. */
    page?: number
}

export interface ApiGenerateCreditIframeRetrieveParams {
    /** The ID of the Tariff to retrieve */
    id: number
}

export interface ApiLandingPageListParams {
    /** A page number within the paginated result set. */
    page?: number
}

export interface ApiOrganizationBranchListParams {
    /** A page number within the paginated result set. */
    page?: number
}

export interface ApiOrganizationListParams {
    /** A page number within the paginated result set. */
    page?: number
}

export interface ApiOrganizationTypesListParams {
    /** A page number within the paginated result set. */
    page?: number
}

export interface ApiPlansListParams {
    /** A page number within the paginated result set. */
    page?: number
    plan__state?: number
}

export interface ApiPoolListParams {
    name?: string
    organization_branch?: number
    organization_branch__name?: string
    /** A page number within the paginated result set. */
    page?: number
    pool_type?: string
}

export interface ApiPoolLogReportAttachmentListParams {
    /** A page number within the paginated result set. */
    page?: number
}

export interface ApiPoolLogReportListParams {
    commented?: boolean
    /** A page number within the paginated result set. */
    page?: number
    pool__id?: number
    pool__name?: string
    pool__organization_branch__name?: string
    /** @format date */
    pool_log_date?: string
    /** @format date */
    pool_log_range_after?: string
    /** @format date */
    pool_log_range_before?: string
    signer?: number
}

export interface ApiPoolTypesListParams {
    /** A page number within the paginated result set. */
    page?: number
}

export interface ApiSchemaRetrieveParams {
    format?: FormatEnum
    lang?: LangEnum
}

export enum ApiSchemaRetrieveParams1FormatEnum {
    Json = 'json',
    Yaml = 'yaml',
}

export enum ApiSchemaRetrieveParams1LangEnum {
    Af = 'af',
    Ar = 'ar',
    ArDz = 'ar-dz',
    Ast = 'ast',
    Az = 'az',
    Be = 'be',
    Bg = 'bg',
    Bn = 'bn',
    Br = 'br',
    Bs = 'bs',
    Ca = 'ca',
    Ckb = 'ckb',
    Cs = 'cs',
    Cy = 'cy',
    Da = 'da',
    De = 'de',
    Dsb = 'dsb',
    El = 'el',
    En = 'en',
    EnAu = 'en-au',
    EnGb = 'en-gb',
    Eo = 'eo',
    Es = 'es',
    EsAr = 'es-ar',
    EsCo = 'es-co',
    EsMx = 'es-mx',
    EsNi = 'es-ni',
    EsVe = 'es-ve',
    Et = 'et',
    Eu = 'eu',
    Fa = 'fa',
    Fi = 'fi',
    Fr = 'fr',
    Fy = 'fy',
    Ga = 'ga',
    Gd = 'gd',
    Gl = 'gl',
    He = 'he',
    Hi = 'hi',
    Hr = 'hr',
    Hsb = 'hsb',
    Hu = 'hu',
    Hy = 'hy',
    Ia = 'ia',
    Id = 'id',
    Ig = 'ig',
    Io = 'io',
    Is = 'is',
    It = 'it',
    Ja = 'ja',
    Ka = 'ka',
    Kab = 'kab',
    Kk = 'kk',
    Km = 'km',
    Kn = 'kn',
    Ko = 'ko',
    Ky = 'ky',
    Lb = 'lb',
    Lt = 'lt',
    Lv = 'lv',
    Mk = 'mk',
    Ml = 'ml',
    Mn = 'mn',
    Mr = 'mr',
    Ms = 'ms',
    My = 'my',
    Nb = 'nb',
    Ne = 'ne',
    Nl = 'nl',
    Nn = 'nn',
    Os = 'os',
    Pa = 'pa',
    Pl = 'pl',
    Pt = 'pt',
    PtBr = 'pt-br',
    Ro = 'ro',
    Ru = 'ru',
    Sk = 'sk',
    Sl = 'sl',
    Sq = 'sq',
    Sr = 'sr',
    SrLatn = 'sr-latn',
    Sv = 'sv',
    Sw = 'sw',
    Ta = 'ta',
    Te = 'te',
    Tg = 'tg',
    Th = 'th',
    Tk = 'tk',
    Tr = 'tr',
    Tt = 'tt',
    Udm = 'udm',
    Ug = 'ug',
    Uk = 'uk',
    Ur = 'ur',
    Uz = 'uz',
    Vi = 'vi',
    ZhHans = 'zh-hans',
    ZhHant = 'zh-hant',
}

export interface ApiSubscriptionListParams {
    /** A page number within the paginated result set. */
    page?: number
}

export interface ApiToxicReportListParams {
    month?: number
    organization_branch?: number
    /** A page number within the paginated result set. */
    page?: number
    /** @format date */
    report_date?: string
    substance_receiver_name?: string
    year?: number
}

export interface ApiToxicReportMonthlyReportsByYearRetrieveParams {
    /** filter by org_branch */
    org_branch: string
    /** filter by year */
    year: string
}

export interface ApiToxicReportRenderPdfReportRetrieveParams {
    date: string
    /** A unique integer value identifying this דוח פנקס רעלים. */
    id: number
    language: string
    /** filter by org_branch */
    org_branch: string
}

export interface ApiToxicReportReportsByMonthRetrieveParams {
    /** filter by month */
    month: string
    /** filter by org_branch */
    org_branch: string
    /** filter by year */
    year: string
}

export interface ApiToxicSubstanceListParams {
    /** A page number within the paginated result set. */
    page?: number
}

export interface ApiUsersListParams {
    /** A page number within the paginated result set. */
    page?: number
}

export interface ComplexType {
    id: number
    /**
     * ישראל בלבד
     * @default false
     */
    israel_only?: boolean
    /**
     * שם לועזי
     * @maxLength 200
     */
    name_english?: string | null
    /**
     * שם עברי
     * @maxLength 200
     */
    name_hebrew?: string | null
}

export interface ComplexTypeRequest {
    /**
     * ישראל בלבד
     * @default false
     */
    israel_only?: boolean
    /**
     * שם לועזי
     * @minLength 1
     * @maxLength 200
     */
    name_english?: string | null
    /**
     * שם עברי
     * @minLength 1
     * @maxLength 200
     */
    name_hebrew?: string | null
}

/**
 * * `AF` - אפגניסטן
 * * `AX` - אולנד
 * * `AL` - אלבניה
 * * `AD` - אנדורה
 * * `AO` - אנגולה
 * * `AI` - אנגווילה
 * * `AQ` - אנטארקטיקה
 * * `AR` - ארגנטינה
 * * `AM` - ארמניה
 * * `AW` - ארובה
 * * `AU` - אוסטרליה
 * * `AT` - אוסטריה
 * * `BH` - בחריין
 * * `BD` - בנגלדש
 * * `BB` - ברבדוס
 * * `BY` - בלרוס
 * * `BE` - בלגיה
 * * `BZ` - בליז
 * * `BJ` - בנין
 * * `BM` - ברמודה
 * * `BT` - בותן
 * * `BO` - בוליביה
 * * `BW` - בוטסואנה
 * * `BR` - ברזיל
 * * `BN` - ברונאי
 * * `BG` - בלוגריה
 * * `BI` - בורונדי
 * * `KH` - קמבודיה
 * * `CM` - קמרון
 * * `CA` - קנדה
 * * `CN` - סין
 * * `CO` - קולומביה
 * * `KM` - קומורו
 * * `CG` - קונגו
 * * `HR` - קרואטיה
 * * `CU` - קובה
 * * `CW` - קוראסאו
 * * `CY` - קפריסין
 * * `DK` - דנמרק
 * * `DM` - דומיניקה
 * * `EC` - אקוודור
 * * `EG` - מצרים
 * * `ER` - אריתריאה
 * * `EE` - אסטוניה
 * * `SZ` - סווזילנד
 * * `ET` - אתיופיה
 * * `FJ` - פיגי
 * * `FI` - פינלנד
 * * `FR` - צרפת
 * * `GA` - גבון
 * * `GM` - גמביה
 * * `GE` - גרוזיה
 * * `DE` - גרמניה
 * * `GH` - גאנה
 * * `GI` - גיברלטר
 * * `GR` - יוון
 * * `GL` - גרינלנד
 * * `GD` - גרנדה
 * * `GP` - גוואדלופ
 * * `GU` - גואם
 * * `GT` - גואטמלה
 * * `GG` - גרנזי
 * * `GN` - גינאה
 * * `GY` - גויאנה
 * * `HT` - האיטי
 * * `HN` - הונדורס
 * * `HU` - הונגריה
 * * `IS` - איסלנד
 * * `IN` - הודו
 * * `ID` - אינדונזיה
 * * `IR` - אירן
 * * `IQ` - עירק
 * * `IE` - אירלנד
 * * `IL` - ישראל
 * * `IT` - איטליה
 * * `JP` - יפן
 * * `JO` - ירדן
 * * `KZ` - קזחסטן
 * * `KE` - קניה
 * * `KI` - קיריבטי
 * * `KW` - כווית
 * * `KG` - קירגיזסטן
 * * `LA` - לאוס
 * * `LV` - לטביה
 * * `LB` - לבנון
 * * `LS` - לסוטו
 * * `LR` - ליבריה
 * * `LY` - לוב
 * * `LI` - ליכטנשטיין
 * * `LT` - ליטא
 * * `LU` - לוקסמבורג
 * * `MO` - מקאו
 * * `MG` - מדגסקר
 * * `MW` - מלאווי
 * * `MY` - מלזיה
 * * `ML` - מאלי
 * * `MT` - מלטה
 * * `MQ` - מרטיניק
 * * `MR` - מאוריטניה
 * * `MU` - מאוריציוס
 * * `YT` - מיוט
 * * `MX` - מקסיקו
 * * `MD` - מולדובה
 * * `MC` - מונקו
 * * `MN` - מונגוליה
 * * `ME` - מונטנגרו
 * * `MS` - מונסראט
 * * `MA` - מרוקו
 * * `MZ` - מוזמביק
 * * `MM` - מיאנמר
 * * `NA` - נמיביה
 * * `NR` - נאורו
 * * `NP` - נפאל
 * * `NL` - הולנד
 * * `NI` - ניקרגואה
 * * `NG` - ניגריה
 * * `NU` - ניואה
 * * `MK` - מקדוניה
 * * `NO` - נורבגיה
 * * `OM` - עומאן
 * * `PK` - פקיסטן
 * * `PS` - פלסטין
 * * `PA` - פנמה
 * * `PY` - פרגוואי
 * * `PE` - פרו
 * * `PH` - פיליפינים
 * * `PL` - פולין
 * * `PT` - פורטוגל
 * * `QA` - קטאר
 * * `RE` - ראוניון
 * * `RO` - רומניה
 * * `RU` - רוסיה
 * * `RW` - רואנדה
 * * `WS` - סמואה
 * * `SN` - סנגל
 * * `RS` - סרביה
 * * `SG` - סינגפור
 * * `SK` - סלובקיה
 * * `SI` - סלובניה
 * * `SO` - סומליה
 * * `ES` - ספרד
 * * `SD` - סודאן
 * * `SR` - סורינאם
 * * `SE` - שבדיה
 * * `CH` - שווייץ
 * * `SY` - סוריה
 * * `TW` - טאיוואן
 * * `TZ` - טנזניה
 * * `TH` - תאילנד
 * * `TG` - טוגו
 * * `TK` - טוקלאו
 * * `TO` - טונגה
 * * `TN` - טוניסיה
 * * `TR` - טורקיה
 * * `TM` - טורקמניסטן
 * * `TV` - טובאלו
 * * `UG` - אוגנדה
 * * `UA` - אוקראינה
 * * `GB` - בריטניה
 * * `UY` - אורוגואי
 * * `UZ` - אוזבקיסטן
 * * `VU` - ונואטו
 * * `VE` - ונצואלה
 * * `VN` - ויאטנם
 * * `YE` - תימן
 * * `ZM` - זמביה
 * * `ZW` - זימבבואה
 * * `AS` - סמואה האמריקנית
 * * `AG` - אנטיגואה וברבודה
 * * `BS` - איי בהאמה
 * * `BQ` - הקריביים ההולנדיים
 * * `BA` - בוסניה והרצגובינה
 * * `BV` - האי בווה
 * * `BF` - בורקינה פאסו
 * * `CV` - כף ורדה
 * * `KY` - איי קיימן
 * * `CK` - איי קוק
 * * `CR` - קוסטה ריקה
 * * `CI` - חוף השנהב
 * * `DO` - הרפובליקה הדומיניקנית
 * * `SV` - אל סלוודור
 * * `GQ` - גינאה המשוונית
 * * `FO` - איי פארו
 * * `GF` - גיאנה הצרפתית
 * * `PF` - פולינזיה הצרפתית
 * * `VA` - הכס הקדוש
 * * `HK` - הונג קונג
 * * `IM` - האי מאן
 * * `KP` - קוריאה הצפונית
 * * `KR` - קוריאה הדרומית
 * * `MV` - האיים המלדיביים
 * * `MH` - איי מרשל
 * * `NC` - קלדוניה החדשה
 * * `NZ` - ניו זילנד
 * * `NF` - האי נורפוק
 * * `PW` - איי פלאו
 * * `PN` - איי פיטקיירן
 * * `PR` - פורטו ריקו
 * * `BL` - סנט ברתלמי
 * * `LC` - סנט לוסיה
 * * `SM` - סן מרינו
 * * `SA` - ערב הסעודית
 * * `SC` - איי סיישל
 * * `SL` - סיירה לאונה
 * * `SB` - איי שלמה
 * * `ZA` - דרום אפריקה
 * * `SS` - דרום סודאן
 * * `LK` - סרי לנקה
 * * `TT` - טרינידד וטובגו
 * * `AE` - איחוד האמירויות
 * * `US` - ארצות הברית
 * * `WF` - וואליס ופוטונה
 * * `EH` - סהרה המערבית
 * * `CF` - הרפובליקה המרכז אפריקאית
 * * `CX` - אי חג המולד
 * * `MP` - איי מריאנה הצפוניים
 * * `PG` - פפואה גינאה החדשה
 * * `KN` - סנט קיטס ונוויס
 * * `PM` - סנט פייר ומיקלון
 * * `VC` - סנט וינסנט והגרנדינים
 * * `ST` - סאו טומה ופרינסיפה
 * * `SJ` - סוולברד ויאן מאיין
 * * `TC` - איי טורקס וקאיקוס
 * * `IO` - הטריטוריה הבריטית באוקיינוס ההודי
 * * `TF` - שטחים דרומיים של צרפת
 * * `HM` - האי הרד ואיי מקדונלד
 * * `UM` - ארצות הברית איי מינור
 * * `MF` - סנט מרטין (החלק הצרפתי)
 * * `SX` - סינט מארטן (חלק הולנדי)
 * * `VI` - איי הבתולה (ארה"ב)
 * * `CC` - איי קוקוס (קילינג)
 * * `VG` - איי הבתולה (בריטניה)
 * * `FK` - איי פוקלנד [מאלווינס]
 * * `GS` - דרום ג'ורג'יה ואיי סנדוויץ 'הדרומיים
 * * `CD` - קונגו (הרפובליקה הדמוקרטית של)
 * * `FM` - מיקרונזיה (המדינות המאוחדות של)
 * * `SH` - סנט הלנה, עלייתה וטריסטן דה קונחה
 * * `DZ` - אלג'יריה
 * * `AZ` - אזרבייג'ן
 * * `TD` - צ'אד
 * * `CL` - צ'ילה
 * * `CZ` - צ'כיה
 * * `DJ` - ג'יבוטי
 * * `JM` - ג'מייקה
 * * `JE` - ג'רזי
 * * `NE` - ניז'ר
 * * `TJ` - טג'יקיסטן
 * * `GW` - גינאה-ביסאו
 * * `TL` - טימור-לסטה
 */
export enum CountryEnum {
    AF = 'AF',
    AX = 'AX',
    AL = 'AL',
    AD = 'AD',
    AO = 'AO',
    AI = 'AI',
    AQ = 'AQ',
    AR = 'AR',
    AM = 'AM',
    AW = 'AW',
    AU = 'AU',
    AT = 'AT',
    BH = 'BH',
    BD = 'BD',
    BB = 'BB',
    BY = 'BY',
    BE = 'BE',
    BZ = 'BZ',
    BJ = 'BJ',
    BM = 'BM',
    BT = 'BT',
    BO = 'BO',
    BW = 'BW',
    BR = 'BR',
    BN = 'BN',
    BG = 'BG',
    BI = 'BI',
    KH = 'KH',
    CM = 'CM',
    CA = 'CA',
    CN = 'CN',
    CO = 'CO',
    KM = 'KM',
    CG = 'CG',
    HR = 'HR',
    CU = 'CU',
    CW = 'CW',
    CY = 'CY',
    DK = 'DK',
    DM = 'DM',
    EC = 'EC',
    EG = 'EG',
    ER = 'ER',
    EE = 'EE',
    SZ = 'SZ',
    ET = 'ET',
    FJ = 'FJ',
    FI = 'FI',
    FR = 'FR',
    GA = 'GA',
    GM = 'GM',
    GE = 'GE',
    DE = 'DE',
    GH = 'GH',
    GI = 'GI',
    GR = 'GR',
    GL = 'GL',
    GD = 'GD',
    GP = 'GP',
    GU = 'GU',
    GT = 'GT',
    GG = 'GG',
    GN = 'GN',
    GY = 'GY',
    HT = 'HT',
    HN = 'HN',
    HU = 'HU',
    IS = 'IS',
    IN = 'IN',
    ID = 'ID',
    IR = 'IR',
    IQ = 'IQ',
    IE = 'IE',
    IL = 'IL',
    IT = 'IT',
    JP = 'JP',
    JO = 'JO',
    KZ = 'KZ',
    KE = 'KE',
    KI = 'KI',
    KW = 'KW',
    KG = 'KG',
    LA = 'LA',
    LV = 'LV',
    LB = 'LB',
    LS = 'LS',
    LR = 'LR',
    LY = 'LY',
    LI = 'LI',
    LT = 'LT',
    LU = 'LU',
    MO = 'MO',
    MG = 'MG',
    MW = 'MW',
    MY = 'MY',
    ML = 'ML',
    MT = 'MT',
    MQ = 'MQ',
    MR = 'MR',
    MU = 'MU',
    YT = 'YT',
    MX = 'MX',
    MD = 'MD',
    MC = 'MC',
    MN = 'MN',
    ME = 'ME',
    MS = 'MS',
    MA = 'MA',
    MZ = 'MZ',
    MM = 'MM',
    NA = 'NA',
    NR = 'NR',
    NP = 'NP',
    NL = 'NL',
    NI = 'NI',
    NG = 'NG',
    NU = 'NU',
    MK = 'MK',
    NO = 'NO',
    OM = 'OM',
    PK = 'PK',
    PS = 'PS',
    PA = 'PA',
    PY = 'PY',
    PE = 'PE',
    PH = 'PH',
    PL = 'PL',
    PT = 'PT',
    QA = 'QA',
    RE = 'RE',
    RO = 'RO',
    RU = 'RU',
    RW = 'RW',
    WS = 'WS',
    SN = 'SN',
    RS = 'RS',
    SG = 'SG',
    SK = 'SK',
    SI = 'SI',
    SO = 'SO',
    ES = 'ES',
    SD = 'SD',
    SR = 'SR',
    SE = 'SE',
    CH = 'CH',
    SY = 'SY',
    TW = 'TW',
    TZ = 'TZ',
    TH = 'TH',
    TG = 'TG',
    TK = 'TK',
    TO = 'TO',
    TN = 'TN',
    TR = 'TR',
    TM = 'TM',
    TV = 'TV',
    UG = 'UG',
    UA = 'UA',
    GB = 'GB',
    UY = 'UY',
    UZ = 'UZ',
    VU = 'VU',
    VE = 'VE',
    VN = 'VN',
    YE = 'YE',
    ZM = 'ZM',
    ZW = 'ZW',
    AS = 'AS',
    AG = 'AG',
    BS = 'BS',
    BQ = 'BQ',
    BA = 'BA',
    BV = 'BV',
    BF = 'BF',
    CV = 'CV',
    KY = 'KY',
    CK = 'CK',
    CR = 'CR',
    CI = 'CI',
    DO = 'DO',
    SV = 'SV',
    GQ = 'GQ',
    FO = 'FO',
    GF = 'GF',
    PF = 'PF',
    VA = 'VA',
    HK = 'HK',
    IM = 'IM',
    KP = 'KP',
    KR = 'KR',
    MV = 'MV',
    MH = 'MH',
    NC = 'NC',
    NZ = 'NZ',
    NF = 'NF',
    PW = 'PW',
    PN = 'PN',
    PR = 'PR',
    BL = 'BL',
    LC = 'LC',
    SM = 'SM',
    SA = 'SA',
    SC = 'SC',
    SL = 'SL',
    SB = 'SB',
    ZA = 'ZA',
    SS = 'SS',
    LK = 'LK',
    TT = 'TT',
    AE = 'AE',
    US = 'US',
    WF = 'WF',
    EH = 'EH',
    CF = 'CF',
    CX = 'CX',
    MP = 'MP',
    PG = 'PG',
    KN = 'KN',
    PM = 'PM',
    VC = 'VC',
    ST = 'ST',
    SJ = 'SJ',
    TC = 'TC',
    IO = 'IO',
    TF = 'TF',
    HM = 'HM',
    UM = 'UM',
    MF = 'MF',
    SX = 'SX',
    VI = 'VI',
    CC = 'CC',
    VG = 'VG',
    FK = 'FK',
    GS = 'GS',
    CD = 'CD',
    FM = 'FM',
    SH = 'SH',
    DZ = 'DZ',
    AZ = 'AZ',
    TD = 'TD',
    CL = 'CL',
    CZ = 'CZ',
    DJ = 'DJ',
    JM = 'JM',
    JE = 'JE',
    NE = 'NE',
    TJ = 'TJ',
    GW = 'GW',
    TL = 'TL',
}

/**
 * * `NIS` - שקל
 * * `EUR` - אירו
 * * `USD` - דולר
 */
export enum CurrencyEnum {
    NIS = 'NIS',
    EUR = 'EUR',
    USD = 'USD',
}

export interface Customer {
    /**
     * לוגו
     * @format uri
     */
    customer_logo?: string | null
    /**
     * שם
     * @maxLength 200
     */
    customer_name: string
    id: number
}

export enum FormatEnum {
    Json = 'json',
    Yaml = 'yaml',
}

export interface IframeResponse {
    price: string
    token: string
}

export interface LandingPage {
    customers: Customer[]
    id: number
    /**
     * שפה
     * @default "hebrew"
     */
    language?: LanguageEnum
    plan_features: PlanFeature[]
    plans: Plan[]
    questions: Question[]
    recommendations: Recommendation[]
    /**
     * סרטון
     * @format uri
     * @maxLength 200
     */
    video?: string | null
}

export enum LangEnum {
    Af = 'af',
    Ar = 'ar',
    ArDz = 'ar-dz',
    Ast = 'ast',
    Az = 'az',
    Be = 'be',
    Bg = 'bg',
    Bn = 'bn',
    Br = 'br',
    Bs = 'bs',
    Ca = 'ca',
    Ckb = 'ckb',
    Cs = 'cs',
    Cy = 'cy',
    Da = 'da',
    De = 'de',
    Dsb = 'dsb',
    El = 'el',
    En = 'en',
    EnAu = 'en-au',
    EnGb = 'en-gb',
    Eo = 'eo',
    Es = 'es',
    EsAr = 'es-ar',
    EsCo = 'es-co',
    EsMx = 'es-mx',
    EsNi = 'es-ni',
    EsVe = 'es-ve',
    Et = 'et',
    Eu = 'eu',
    Fa = 'fa',
    Fi = 'fi',
    Fr = 'fr',
    Fy = 'fy',
    Ga = 'ga',
    Gd = 'gd',
    Gl = 'gl',
    He = 'he',
    Hi = 'hi',
    Hr = 'hr',
    Hsb = 'hsb',
    Hu = 'hu',
    Hy = 'hy',
    Ia = 'ia',
    Id = 'id',
    Ig = 'ig',
    Io = 'io',
    Is = 'is',
    It = 'it',
    Ja = 'ja',
    Ka = 'ka',
    Kab = 'kab',
    Kk = 'kk',
    Km = 'km',
    Kn = 'kn',
    Ko = 'ko',
    Ky = 'ky',
    Lb = 'lb',
    Lt = 'lt',
    Lv = 'lv',
    Mk = 'mk',
    Ml = 'ml',
    Mn = 'mn',
    Mr = 'mr',
    Ms = 'ms',
    My = 'my',
    Nb = 'nb',
    Ne = 'ne',
    Nl = 'nl',
    Nn = 'nn',
    Os = 'os',
    Pa = 'pa',
    Pl = 'pl',
    Pt = 'pt',
    PtBr = 'pt-br',
    Ro = 'ro',
    Ru = 'ru',
    Sk = 'sk',
    Sl = 'sl',
    Sq = 'sq',
    Sr = 'sr',
    SrLatn = 'sr-latn',
    Sv = 'sv',
    Sw = 'sw',
    Ta = 'ta',
    Te = 'te',
    Tg = 'tg',
    Th = 'th',
    Tk = 'tk',
    Tr = 'tr',
    Tt = 'tt',
    Udm = 'udm',
    Ug = 'ug',
    Uk = 'uk',
    Ur = 'ur',
    Uz = 'uz',
    Vi = 'vi',
    ZhHans = 'zh-hans',
    ZhHant = 'zh-hant',
}

/**
 * * `hebrew` - עברית
 * * `english` - אנגלית
 */
export enum LanguageEnum {
    Hebrew = 'hebrew',
    English = 'english',
}

export interface Leads {
    /** תוכן */
    content?: string | null
    /**
     * מועד יצירה
     * @format date-time
     */
    created_at: string
    id: number
    /** שם */
    name?: string | null
    /** מספר טלפון */
    phone?: string | null
}

export interface LeadsRequest {
    /**
     * תוכן
     * @minLength 1
     */
    content?: string | null
    /**
     * שם
     * @minLength 1
     */
    name?: string | null
    /**
     * מספר טלפון
     * @minLength 1
     */
    phone?: string | null
}

export interface LoginRequest {
    /** @minLength 1 */
    phone: string
}

export interface ManagerList {
    /**
     * שם פרטי
     * @maxLength 150
     */
    first_name: string
    full_name: string
    id: number
    /**
     * שם משפחה
     * @maxLength 150
     */
    last_name?: string
    /**
     * מספר טלפון
     * @maxLength 128
     */
    phone?: string | null
    role: RoleEnum
    /**
     * תמונת משתמש
     * @format uri
     */
    user_photo?: string | null
}

export interface ManagerListRequest {
    /**
     * שם פרטי
     * @minLength 1
     * @maxLength 150
     */
    first_name: string
    /**
     * שם משפחה
     * @maxLength 150
     */
    last_name?: string
    /**
     * מספר טלפון
     * @maxLength 128
     */
    phone?: string | null
    /**
     * תמונת משתמש
     * @format binary
     */
    user_photo?: File | null
}

export type NullEnum = null

export interface Organization {
    id: number
    /**
     * שם
     * @maxLength 200
     */
    name: string
}

export interface OrganizationBranch {
    active_subscription: Subscription | null
    /**
     * מזהה יישות
     * @maxLength 20
     */
    company_id?: string | null
    complex_type: ComplexType
    /** מדינה */
    country?: number | null
    country_code: string
    /**
     * מועד יצירה
     * @format date-time
     */
    created_at: string
    days_to_trial_end: number
    id: number
    /**
     * מייל למשלוח חשבונית
     * @format email
     * @maxLength 254
     */
    invoice_email?: string | null
    is_trial_mode: boolean
    managers: PublicUser[]
    masav_enabled: string
    /**
     * סכום מקסימום בסליקה במס״ב
     * @format decimal
     * @default "0.00"
     * @pattern ^-?\d{0,18}(?:\.\d{0,2})?$
     */
    max_amount_masav?: string | null
    /**
     * שם
     * @maxLength 200
     */
    name: string
    /**
     * מספר טלפון
     * @maxLength 128
     */
    office_phone?: string | null
    organization?: Organization
    /**
     * כתובת הסניף
     * @maxLength 200
     */
    organization_branch_address: string
    /**
     * מספר היתר רעלים
     * @maxLength 200
     */
    toxic_permit?: string | null
    /** @format date */
    trial_end_date: string
}

export interface OrganizationBranchRequest {
    /**
     * מזהה יישות
     * @maxLength 20
     */
    company_id?: string | null
    complex_type_id: number
    /** מדינה */
    country?: number | null
    /**
     * מייל למשלוח חשבונית
     * @format email
     * @maxLength 254
     */
    invoice_email?: string | null
    /**
     * סכום מקסימום בסליקה במס״ב
     * @format decimal
     * @default "0.00"
     * @pattern ^-?\d{0,18}(?:\.\d{0,2})?$
     */
    max_amount_masav?: string | null
    /**
     * שם
     * @minLength 1
     * @maxLength 200
     */
    name: string
    /**
     * מספר טלפון
     * @maxLength 128
     */
    office_phone?: string | null
    organization?: OrganizationRequest
    /**
     * כתובת הסניף
     * @minLength 1
     * @maxLength 200
     */
    organization_branch_address: string
    organization_id?: number
    /**
     * מספר היתר רעלים
     * @maxLength 200
     */
    toxic_permit?: string | null
}

export interface OrganizationRequest {
    /**
     * שם
     * @minLength 1
     * @maxLength 200
     */
    name: string
}

export interface PaginatedAgreementList {
    /** @example 123 */
    count: number
    /**
     * @format uri
     * @example "http://api.example.org/accounts/?page=4"
     */
    next?: string | null
    /**
     * @format uri
     * @example "http://api.example.org/accounts/?page=2"
     */
    previous?: string | null
    results: Agreement[]
}

export interface PaginatedComplexTypeList {
    /** @example 123 */
    count: number
    /**
     * @format uri
     * @example "http://api.example.org/accounts/?page=4"
     */
    next?: string | null
    /**
     * @format uri
     * @example "http://api.example.org/accounts/?page=2"
     */
    previous?: string | null
    results: ComplexType[]
}

export interface PaginatedLandingPageList {
    /** @example 123 */
    count: number
    /**
     * @format uri
     * @example "http://api.example.org/accounts/?page=4"
     */
    next?: string | null
    /**
     * @format uri
     * @example "http://api.example.org/accounts/?page=2"
     */
    previous?: string | null
    results: LandingPage[]
}

export interface PaginatedManagerListList {
    /** @example 123 */
    count: number
    /**
     * @format uri
     * @example "http://api.example.org/accounts/?page=4"
     */
    next?: string | null
    /**
     * @format uri
     * @example "http://api.example.org/accounts/?page=2"
     */
    previous?: string | null
    results: ManagerList[]
}

export interface PaginatedOrganizationBranchList {
    /** @example 123 */
    count: number
    /**
     * @format uri
     * @example "http://api.example.org/accounts/?page=4"
     */
    next?: string | null
    /**
     * @format uri
     * @example "http://api.example.org/accounts/?page=2"
     */
    previous?: string | null
    results: OrganizationBranch[]
}

export interface PaginatedOrganizationList {
    /** @example 123 */
    count: number
    /**
     * @format uri
     * @example "http://api.example.org/accounts/?page=4"
     */
    next?: string | null
    /**
     * @format uri
     * @example "http://api.example.org/accounts/?page=2"
     */
    previous?: string | null
    results: Organization[]
}

export interface PaginatedPaymentStateList {
    /** @example 123 */
    count: number
    /**
     * @format uri
     * @example "http://api.example.org/accounts/?page=4"
     */
    next?: string | null
    /**
     * @format uri
     * @example "http://api.example.org/accounts/?page=2"
     */
    previous?: string | null
    results: PaymentState[]
}

export interface PaginatedPlanList {
    /** @example 123 */
    count: number
    /**
     * @format uri
     * @example "http://api.example.org/accounts/?page=4"
     */
    next?: string | null
    /**
     * @format uri
     * @example "http://api.example.org/accounts/?page=2"
     */
    previous?: string | null
    results: Plan[]
}

export interface PaginatedPoolList {
    /** @example 123 */
    count: number
    /**
     * @format uri
     * @example "http://api.example.org/accounts/?page=4"
     */
    next?: string | null
    /**
     * @format uri
     * @example "http://api.example.org/accounts/?page=2"
     */
    previous?: string | null
    results: Pool[]
}

export interface PaginatedPoolLogReportAttachmentList {
    /** @example 123 */
    count: number
    /**
     * @format uri
     * @example "http://api.example.org/accounts/?page=4"
     */
    next?: string | null
    /**
     * @format uri
     * @example "http://api.example.org/accounts/?page=2"
     */
    previous?: string | null
    results: PoolLogReportAttachment[]
}

export interface PaginatedPoolLogReportList {
    /** @example 123 */
    count: number
    /**
     * @format uri
     * @example "http://api.example.org/accounts/?page=4"
     */
    next?: string | null
    /**
     * @format uri
     * @example "http://api.example.org/accounts/?page=2"
     */
    previous?: string | null
    results: PoolLogReport[]
}

export interface PaginatedPoolTypeList {
    /** @example 123 */
    count: number
    /**
     * @format uri
     * @example "http://api.example.org/accounts/?page=4"
     */
    next?: string | null
    /**
     * @format uri
     * @example "http://api.example.org/accounts/?page=2"
     */
    previous?: string | null
    results: PoolType[]
}

export interface PaginatedSubscriptionList {
    /** @example 123 */
    count: number
    /**
     * @format uri
     * @example "http://api.example.org/accounts/?page=4"
     */
    next?: string | null
    /**
     * @format uri
     * @example "http://api.example.org/accounts/?page=2"
     */
    previous?: string | null
    results: Subscription[]
}

export interface PaginatedToxicReportList {
    /** @example 123 */
    count: number
    /**
     * @format uri
     * @example "http://api.example.org/accounts/?page=4"
     */
    next?: string | null
    /**
     * @format uri
     * @example "http://api.example.org/accounts/?page=2"
     */
    previous?: string | null
    results: ToxicReport[]
}

export interface PaginatedToxicSubstanceList {
    /** @example 123 */
    count: number
    /**
     * @format uri
     * @example "http://api.example.org/accounts/?page=4"
     */
    next?: string | null
    /**
     * @format uri
     * @example "http://api.example.org/accounts/?page=2"
     */
    previous?: string | null
    results: ToxicSubstance[]
}

export interface PaginatedUserDetailsList {
    /** @example 123 */
    count: number
    /**
     * @format uri
     * @example "http://api.example.org/accounts/?page=4"
     */
    next?: string | null
    /**
     * @format uri
     * @example "http://api.example.org/accounts/?page=2"
     */
    previous?: string | null
    results: UserDetails[]
}

export interface PatchedAgreementRequest {
    /** סוג הסכם */
    agreement_form_type?: AgreementFormTypeEnum
    /** שפת הסכם */
    agreement_language?: AgreementLanguageEnum
    /**
     * תוכן
     * @minLength 1
     */
    content?: string
}

export interface PatchedManagerListRequest {
    /**
     * שם פרטי
     * @minLength 1
     * @maxLength 150
     */
    first_name?: string
    /**
     * שם משפחה
     * @maxLength 150
     */
    last_name?: string
    /**
     * מספר טלפון
     * @maxLength 128
     */
    phone?: string | null
    /**
     * תמונת משתמש
     * @format binary
     */
    user_photo?: File | null
}

export interface PatchedOrganizationBranchRequest {
    /**
     * מזהה יישות
     * @maxLength 20
     */
    company_id?: string | null
    complex_type_id?: number
    /** מדינה */
    country?: number | null
    /**
     * מייל למשלוח חשבונית
     * @format email
     * @maxLength 254
     */
    invoice_email?: string | null
    /**
     * סכום מקסימום בסליקה במס״ב
     * @format decimal
     * @default "0.00"
     * @pattern ^-?\d{0,18}(?:\.\d{0,2})?$
     */
    max_amount_masav?: string | null
    /**
     * שם
     * @minLength 1
     * @maxLength 200
     */
    name?: string
    /**
     * מספר טלפון
     * @maxLength 128
     */
    office_phone?: string | null
    organization?: OrganizationRequest
    /**
     * כתובת הסניף
     * @minLength 1
     * @maxLength 200
     */
    organization_branch_address?: string
    organization_id?: number
    /**
     * מספר היתר רעלים
     * @maxLength 200
     */
    toxic_permit?: string | null
}

export interface PatchedOrganizationRequest {
    /**
     * שם
     * @minLength 1
     * @maxLength 200
     */
    name?: string
}

export interface PatchedPaymentStateRequest {
    /**
     * * `AF` - אפגניסטן
     * * `AX` - אולנד
     * * `AL` - אלבניה
     * * `AD` - אנדורה
     * * `AO` - אנגולה
     * * `AI` - אנגווילה
     * * `AQ` - אנטארקטיקה
     * * `AR` - ארגנטינה
     * * `AM` - ארמניה
     * * `AW` - ארובה
     * * `AU` - אוסטרליה
     * * `AT` - אוסטריה
     * * `BH` - בחריין
     * * `BD` - בנגלדש
     * * `BB` - ברבדוס
     * * `BY` - בלרוס
     * * `BE` - בלגיה
     * * `BZ` - בליז
     * * `BJ` - בנין
     * * `BM` - ברמודה
     * * `BT` - בותן
     * * `BO` - בוליביה
     * * `BW` - בוטסואנה
     * * `BR` - ברזיל
     * * `BN` - ברונאי
     * * `BG` - בלוגריה
     * * `BI` - בורונדי
     * * `KH` - קמבודיה
     * * `CM` - קמרון
     * * `CA` - קנדה
     * * `CN` - סין
     * * `CO` - קולומביה
     * * `KM` - קומורו
     * * `CG` - קונגו
     * * `HR` - קרואטיה
     * * `CU` - קובה
     * * `CW` - קוראסאו
     * * `CY` - קפריסין
     * * `DK` - דנמרק
     * * `DM` - דומיניקה
     * * `EC` - אקוודור
     * * `EG` - מצרים
     * * `ER` - אריתריאה
     * * `EE` - אסטוניה
     * * `SZ` - סווזילנד
     * * `ET` - אתיופיה
     * * `FJ` - פיגי
     * * `FI` - פינלנד
     * * `FR` - צרפת
     * * `GA` - גבון
     * * `GM` - גמביה
     * * `GE` - גרוזיה
     * * `DE` - גרמניה
     * * `GH` - גאנה
     * * `GI` - גיברלטר
     * * `GR` - יוון
     * * `GL` - גרינלנד
     * * `GD` - גרנדה
     * * `GP` - גוואדלופ
     * * `GU` - גואם
     * * `GT` - גואטמלה
     * * `GG` - גרנזי
     * * `GN` - גינאה
     * * `GY` - גויאנה
     * * `HT` - האיטי
     * * `HN` - הונדורס
     * * `HU` - הונגריה
     * * `IS` - איסלנד
     * * `IN` - הודו
     * * `ID` - אינדונזיה
     * * `IR` - אירן
     * * `IQ` - עירק
     * * `IE` - אירלנד
     * * `IL` - ישראל
     * * `IT` - איטליה
     * * `JP` - יפן
     * * `JO` - ירדן
     * * `KZ` - קזחסטן
     * * `KE` - קניה
     * * `KI` - קיריבטי
     * * `KW` - כווית
     * * `KG` - קירגיזסטן
     * * `LA` - לאוס
     * * `LV` - לטביה
     * * `LB` - לבנון
     * * `LS` - לסוטו
     * * `LR` - ליבריה
     * * `LY` - לוב
     * * `LI` - ליכטנשטיין
     * * `LT` - ליטא
     * * `LU` - לוקסמבורג
     * * `MO` - מקאו
     * * `MG` - מדגסקר
     * * `MW` - מלאווי
     * * `MY` - מלזיה
     * * `ML` - מאלי
     * * `MT` - מלטה
     * * `MQ` - מרטיניק
     * * `MR` - מאוריטניה
     * * `MU` - מאוריציוס
     * * `YT` - מיוט
     * * `MX` - מקסיקו
     * * `MD` - מולדובה
     * * `MC` - מונקו
     * * `MN` - מונגוליה
     * * `ME` - מונטנגרו
     * * `MS` - מונסראט
     * * `MA` - מרוקו
     * * `MZ` - מוזמביק
     * * `MM` - מיאנמר
     * * `NA` - נמיביה
     * * `NR` - נאורו
     * * `NP` - נפאל
     * * `NL` - הולנד
     * * `NI` - ניקרגואה
     * * `NG` - ניגריה
     * * `NU` - ניואה
     * * `MK` - מקדוניה
     * * `NO` - נורבגיה
     * * `OM` - עומאן
     * * `PK` - פקיסטן
     * * `PS` - פלסטין
     * * `PA` - פנמה
     * * `PY` - פרגוואי
     * * `PE` - פרו
     * * `PH` - פיליפינים
     * * `PL` - פולין
     * * `PT` - פורטוגל
     * * `QA` - קטאר
     * * `RE` - ראוניון
     * * `RO` - רומניה
     * * `RU` - רוסיה
     * * `RW` - רואנדה
     * * `WS` - סמואה
     * * `SN` - סנגל
     * * `RS` - סרביה
     * * `SG` - סינגפור
     * * `SK` - סלובקיה
     * * `SI` - סלובניה
     * * `SO` - סומליה
     * * `ES` - ספרד
     * * `SD` - סודאן
     * * `SR` - סורינאם
     * * `SE` - שבדיה
     * * `CH` - שווייץ
     * * `SY` - סוריה
     * * `TW` - טאיוואן
     * * `TZ` - טנזניה
     * * `TH` - תאילנד
     * * `TG` - טוגו
     * * `TK` - טוקלאו
     * * `TO` - טונגה
     * * `TN` - טוניסיה
     * * `TR` - טורקיה
     * * `TM` - טורקמניסטן
     * * `TV` - טובאלו
     * * `UG` - אוגנדה
     * * `UA` - אוקראינה
     * * `GB` - בריטניה
     * * `UY` - אורוגואי
     * * `UZ` - אוזבקיסטן
     * * `VU` - ונואטו
     * * `VE` - ונצואלה
     * * `VN` - ויאטנם
     * * `YE` - תימן
     * * `ZM` - זמביה
     * * `ZW` - זימבבואה
     * * `AS` - סמואה האמריקנית
     * * `AG` - אנטיגואה וברבודה
     * * `BS` - איי בהאמה
     * * `BQ` - הקריביים ההולנדיים
     * * `BA` - בוסניה והרצגובינה
     * * `BV` - האי בווה
     * * `BF` - בורקינה פאסו
     * * `CV` - כף ורדה
     * * `KY` - איי קיימן
     * * `CK` - איי קוק
     * * `CR` - קוסטה ריקה
     * * `CI` - חוף השנהב
     * * `DO` - הרפובליקה הדומיניקנית
     * * `SV` - אל סלוודור
     * * `GQ` - גינאה המשוונית
     * * `FO` - איי פארו
     * * `GF` - גיאנה הצרפתית
     * * `PF` - פולינזיה הצרפתית
     * * `VA` - הכס הקדוש
     * * `HK` - הונג קונג
     * * `IM` - האי מאן
     * * `KP` - קוריאה הצפונית
     * * `KR` - קוריאה הדרומית
     * * `MV` - האיים המלדיביים
     * * `MH` - איי מרשל
     * * `NC` - קלדוניה החדשה
     * * `NZ` - ניו זילנד
     * * `NF` - האי נורפוק
     * * `PW` - איי פלאו
     * * `PN` - איי פיטקיירן
     * * `PR` - פורטו ריקו
     * * `BL` - סנט ברתלמי
     * * `LC` - סנט לוסיה
     * * `SM` - סן מרינו
     * * `SA` - ערב הסעודית
     * * `SC` - איי סיישל
     * * `SL` - סיירה לאונה
     * * `SB` - איי שלמה
     * * `ZA` - דרום אפריקה
     * * `SS` - דרום סודאן
     * * `LK` - סרי לנקה
     * * `TT` - טרינידד וטובגו
     * * `AE` - איחוד האמירויות
     * * `US` - ארצות הברית
     * * `WF` - וואליס ופוטונה
     * * `EH` - סהרה המערבית
     * * `CF` - הרפובליקה המרכז אפריקאית
     * * `CX` - אי חג המולד
     * * `MP` - איי מריאנה הצפוניים
     * * `PG` - פפואה גינאה החדשה
     * * `KN` - סנט קיטס ונוויס
     * * `PM` - סנט פייר ומיקלון
     * * `VC` - סנט וינסנט והגרנדינים
     * * `ST` - סאו טומה ופרינסיפה
     * * `SJ` - סוולברד ויאן מאיין
     * * `TC` - איי טורקס וקאיקוס
     * * `IO` - הטריטוריה הבריטית באוקיינוס ההודי
     * * `TF` - שטחים דרומיים של צרפת
     * * `HM` - האי הרד ואיי מקדונלד
     * * `UM` - ארצות הברית איי מינור
     * * `MF` - סנט מרטין (החלק הצרפתי)
     * * `SX` - סינט מארטן (חלק הולנדי)
     * * `VI` - איי הבתולה (ארה"ב)
     * * `CC` - איי קוקוס (קילינג)
     * * `VG` - איי הבתולה (בריטניה)
     * * `FK` - איי פוקלנד [מאלווינס]
     * * `GS` - דרום ג'ורג'יה ואיי סנדוויץ 'הדרומיים
     * * `CD` - קונגו (הרפובליקה הדמוקרטית של)
     * * `FM` - מיקרונזיה (המדינות המאוחדות של)
     * * `SH` - סנט הלנה, עלייתה וטריסטן דה קונחה
     * * `DZ` - אלג'יריה
     * * `AZ` - אזרבייג'ן
     * * `TD` - צ'אד
     * * `CL` - צ'ילה
     * * `CZ` - צ'כיה
     * * `DJ` - ג'יבוטי
     * * `JM` - ג'מייקה
     * * `JE` - ג'רזי
     * * `NE` - ניז'ר
     * * `TJ` - טג'יקיסטן
     * * `GW` - גינאה-ביסאו
     * * `TL` - טימור-לסטה
     */
    country?: CountryEnum
    /**
     * מטבע
     * @default "NIS"
     */
    currency?: CurrencyEnum
    /**
     * מע״מ
     * לדוגמא - על מנת להזין 17% מעמ יש להזין 1.17
     * @format decimal
     * @pattern ^-?\d{0,2}(?:\.\d{0,2})?$
     */
    vat?: string
}

export interface PatchedPoolLogReportAttachmentRequest {
    /**
     * שם הנספח
     * @minLength 1
     * @maxLength 200
     */
    attachment_name?: string
    /**
     * קישור לנספח
     * @format binary
     */
    attachment_url?: File
    /** דוח יומן בריכה */
    pool_log_report?: number
}

export interface PatchedPoolLogReportRequest {
    /** הערות */
    comments?: string | null
    /**
     * כלור חופשי
     * ppm
     * @format decimal
     * @pattern ^-?\d{0,6}(?:\.\d{0,2})?$
     */
    free_chlorine?: string | null
    /**
     * כלור כללי
     * ppm
     * @format decimal
     * @pattern ^-?\d{0,6}(?:\.\d{0,2})?$
     */
    general_chlorine?: string | null
    /**
     * יש שימוש בחומצה ציאנורית
     * @default false
     */
    is_using_cyanuric_acid?: boolean | null
    /**
     * יש שימוש בהלוברום
     * @default false
     */
    is_using_halobrom?: boolean | null
    /**
     * עכירות
     * NTU
     * @format decimal
     * @pattern ^-?\d{0,6}(?:\.\d{0,2})?$
     */
    opacity?: string | null
    /**
     * ערך הגבה
     * pH
     * @format decimal
     * @pattern ^-?\d{0,6}(?:\.\d{0,2})?$
     */
    ph?: string | null
    /** בריכה */
    pool?: number
    /**
     * תאריך הבדיקה של הבריכה
     * @format date-time
     */
    pool_log_date?: string
    /**
     * רדוקס
     * @format decimal
     * @pattern ^-?\d{0,7}(?:\.\d{0,3})?$
     */
    redox?: string | null
    /** @minLength 1 */
    signature?: string
    signer?: number | null
    /**
     * טמפרטורה
     * C°
     * @format decimal
     * @pattern ^-?\d{0,6}(?:\.\d{0,2})?$
     */
    temperature?: string | null
    /**
     * כלור קשור
     * ppm
     * @format decimal
     * @pattern ^-?\d{0,6}(?:\.\d{0,2})?$
     */
    tied_chlorine?: string | null
}

export interface PatchedPoolRequest {
    /** הערות */
    comments?: string | null
    /**
     * שם
     * @minLength 1
     * @maxLength 200
     */
    name?: string
    organization_branch?: number
    /**
     * כתובת הבריכה
     * @maxLength 30
     */
    pool_address?: string | null
    /**
     * תמונה של הבריכה
     * @format binary
     */
    pool_image?: File | null
    pool_type_id?: number
}

export interface PatchedPoolTypeRequest {
    /**
     * שם לועזי
     * @minLength 1
     * @maxLength 20
     */
    name_english?: string | null
    /**
     * שם עברי
     * @minLength 1
     * @maxLength 20
     */
    name_hebrew?: string | null
}

export interface PatchedSubscriptionRequest {
    /**
     * מספר חשבון בנק
     * @maxLength 200
     */
    bank_account_number?: string | null
    /**
     * מספר סניף בנק
     * @maxLength 200
     */
    bank_branch_number?: string | null
    /**
     * מספר בנק
     * @maxLength 200
     */
    bank_number?: string | null
    /**
     * תוקף אשראי - חודש
     * @maxLength 20
     */
    credit_card_expiry_month?: string | null
    /**
     * תוקף אשראי - שנה
     * @maxLength 20
     */
    credit_card_expiry_year?: string | null
    /**
     * ביטל חידוש אוטומטי
     * @default false
     */
    has_cancelled?: boolean
    /**
     * תשלומים
     * @min -2147483648
     * @max 2147483647
     * @default 1
     */
    installments?: number
    /**
     * חודש לאחרונה בתאריך
     * @format date
     */
    last_renewed_at?: string | null
    /**
     * מנוי נעול
     * מנויים שלא חידשו מנוי והגיע יום הסיום שלהם.
     * @default false
     */
    locked?: boolean
    /** מתחם בריכות */
    organization_branch?: number
    /** משלם המנוי */
    subscriber?: number | null
    /**
     * מזהה מנוי
     * @maxLength 200
     */
    subscription_id?: string | null
}

export interface PatchedUserDetailsRequest {
    /**
     * כתובת דוא"ל
     * @format email
     * @maxLength 254
     */
    email?: string
    /**
     * שם פרטי
     * @minLength 1
     * @maxLength 150
     */
    first_name?: string
    /**
     * שם משפחה
     * @maxLength 150
     */
    last_name?: string
    /**
     * מספר טלפון
     * @maxLength 128
     */
    phone?: string | null
    /**
     * תמונת משתמש
     * @format binary
     */
    user_photo?: File | null
}

export interface PaymentLinkRequestRequest {
    bank_account_number: number
    bank_branch_number: number
    bank_number: number
    /** @minLength 1 */
    company_id: string
    /**
     * @format email
     * @minLength 1
     */
    invoice_email: string
    /** @minLength 1 */
    invoice_name: string
    org_branch: number
    tariff_id: number
}

export interface PaymentState {
    /**
     * * `AF` - אפגניסטן
     * * `AX` - אולנד
     * * `AL` - אלבניה
     * * `AD` - אנדורה
     * * `AO` - אנגולה
     * * `AI` - אנגווילה
     * * `AQ` - אנטארקטיקה
     * * `AR` - ארגנטינה
     * * `AM` - ארמניה
     * * `AW` - ארובה
     * * `AU` - אוסטרליה
     * * `AT` - אוסטריה
     * * `BH` - בחריין
     * * `BD` - בנגלדש
     * * `BB` - ברבדוס
     * * `BY` - בלרוס
     * * `BE` - בלגיה
     * * `BZ` - בליז
     * * `BJ` - בנין
     * * `BM` - ברמודה
     * * `BT` - בותן
     * * `BO` - בוליביה
     * * `BW` - בוטסואנה
     * * `BR` - ברזיל
     * * `BN` - ברונאי
     * * `BG` - בלוגריה
     * * `BI` - בורונדי
     * * `KH` - קמבודיה
     * * `CM` - קמרון
     * * `CA` - קנדה
     * * `CN` - סין
     * * `CO` - קולומביה
     * * `KM` - קומורו
     * * `CG` - קונגו
     * * `HR` - קרואטיה
     * * `CU` - קובה
     * * `CW` - קוראסאו
     * * `CY` - קפריסין
     * * `DK` - דנמרק
     * * `DM` - דומיניקה
     * * `EC` - אקוודור
     * * `EG` - מצרים
     * * `ER` - אריתריאה
     * * `EE` - אסטוניה
     * * `SZ` - סווזילנד
     * * `ET` - אתיופיה
     * * `FJ` - פיגי
     * * `FI` - פינלנד
     * * `FR` - צרפת
     * * `GA` - גבון
     * * `GM` - גמביה
     * * `GE` - גרוזיה
     * * `DE` - גרמניה
     * * `GH` - גאנה
     * * `GI` - גיברלטר
     * * `GR` - יוון
     * * `GL` - גרינלנד
     * * `GD` - גרנדה
     * * `GP` - גוואדלופ
     * * `GU` - גואם
     * * `GT` - גואטמלה
     * * `GG` - גרנזי
     * * `GN` - גינאה
     * * `GY` - גויאנה
     * * `HT` - האיטי
     * * `HN` - הונדורס
     * * `HU` - הונגריה
     * * `IS` - איסלנד
     * * `IN` - הודו
     * * `ID` - אינדונזיה
     * * `IR` - אירן
     * * `IQ` - עירק
     * * `IE` - אירלנד
     * * `IL` - ישראל
     * * `IT` - איטליה
     * * `JP` - יפן
     * * `JO` - ירדן
     * * `KZ` - קזחסטן
     * * `KE` - קניה
     * * `KI` - קיריבטי
     * * `KW` - כווית
     * * `KG` - קירגיזסטן
     * * `LA` - לאוס
     * * `LV` - לטביה
     * * `LB` - לבנון
     * * `LS` - לסוטו
     * * `LR` - ליבריה
     * * `LY` - לוב
     * * `LI` - ליכטנשטיין
     * * `LT` - ליטא
     * * `LU` - לוקסמבורג
     * * `MO` - מקאו
     * * `MG` - מדגסקר
     * * `MW` - מלאווי
     * * `MY` - מלזיה
     * * `ML` - מאלי
     * * `MT` - מלטה
     * * `MQ` - מרטיניק
     * * `MR` - מאוריטניה
     * * `MU` - מאוריציוס
     * * `YT` - מיוט
     * * `MX` - מקסיקו
     * * `MD` - מולדובה
     * * `MC` - מונקו
     * * `MN` - מונגוליה
     * * `ME` - מונטנגרו
     * * `MS` - מונסראט
     * * `MA` - מרוקו
     * * `MZ` - מוזמביק
     * * `MM` - מיאנמר
     * * `NA` - נמיביה
     * * `NR` - נאורו
     * * `NP` - נפאל
     * * `NL` - הולנד
     * * `NI` - ניקרגואה
     * * `NG` - ניגריה
     * * `NU` - ניואה
     * * `MK` - מקדוניה
     * * `NO` - נורבגיה
     * * `OM` - עומאן
     * * `PK` - פקיסטן
     * * `PS` - פלסטין
     * * `PA` - פנמה
     * * `PY` - פרגוואי
     * * `PE` - פרו
     * * `PH` - פיליפינים
     * * `PL` - פולין
     * * `PT` - פורטוגל
     * * `QA` - קטאר
     * * `RE` - ראוניון
     * * `RO` - רומניה
     * * `RU` - רוסיה
     * * `RW` - רואנדה
     * * `WS` - סמואה
     * * `SN` - סנגל
     * * `RS` - סרביה
     * * `SG` - סינגפור
     * * `SK` - סלובקיה
     * * `SI` - סלובניה
     * * `SO` - סומליה
     * * `ES` - ספרד
     * * `SD` - סודאן
     * * `SR` - סורינאם
     * * `SE` - שבדיה
     * * `CH` - שווייץ
     * * `SY` - סוריה
     * * `TW` - טאיוואן
     * * `TZ` - טנזניה
     * * `TH` - תאילנד
     * * `TG` - טוגו
     * * `TK` - טוקלאו
     * * `TO` - טונגה
     * * `TN` - טוניסיה
     * * `TR` - טורקיה
     * * `TM` - טורקמניסטן
     * * `TV` - טובאלו
     * * `UG` - אוגנדה
     * * `UA` - אוקראינה
     * * `GB` - בריטניה
     * * `UY` - אורוגואי
     * * `UZ` - אוזבקיסטן
     * * `VU` - ונואטו
     * * `VE` - ונצואלה
     * * `VN` - ויאטנם
     * * `YE` - תימן
     * * `ZM` - זמביה
     * * `ZW` - זימבבואה
     * * `AS` - סמואה האמריקנית
     * * `AG` - אנטיגואה וברבודה
     * * `BS` - איי בהאמה
     * * `BQ` - הקריביים ההולנדיים
     * * `BA` - בוסניה והרצגובינה
     * * `BV` - האי בווה
     * * `BF` - בורקינה פאסו
     * * `CV` - כף ורדה
     * * `KY` - איי קיימן
     * * `CK` - איי קוק
     * * `CR` - קוסטה ריקה
     * * `CI` - חוף השנהב
     * * `DO` - הרפובליקה הדומיניקנית
     * * `SV` - אל סלוודור
     * * `GQ` - גינאה המשוונית
     * * `FO` - איי פארו
     * * `GF` - גיאנה הצרפתית
     * * `PF` - פולינזיה הצרפתית
     * * `VA` - הכס הקדוש
     * * `HK` - הונג קונג
     * * `IM` - האי מאן
     * * `KP` - קוריאה הצפונית
     * * `KR` - קוריאה הדרומית
     * * `MV` - האיים המלדיביים
     * * `MH` - איי מרשל
     * * `NC` - קלדוניה החדשה
     * * `NZ` - ניו זילנד
     * * `NF` - האי נורפוק
     * * `PW` - איי פלאו
     * * `PN` - איי פיטקיירן
     * * `PR` - פורטו ריקו
     * * `BL` - סנט ברתלמי
     * * `LC` - סנט לוסיה
     * * `SM` - סן מרינו
     * * `SA` - ערב הסעודית
     * * `SC` - איי סיישל
     * * `SL` - סיירה לאונה
     * * `SB` - איי שלמה
     * * `ZA` - דרום אפריקה
     * * `SS` - דרום סודאן
     * * `LK` - סרי לנקה
     * * `TT` - טרינידד וטובגו
     * * `AE` - איחוד האמירויות
     * * `US` - ארצות הברית
     * * `WF` - וואליס ופוטונה
     * * `EH` - סהרה המערבית
     * * `CF` - הרפובליקה המרכז אפריקאית
     * * `CX` - אי חג המולד
     * * `MP` - איי מריאנה הצפוניים
     * * `PG` - פפואה גינאה החדשה
     * * `KN` - סנט קיטס ונוויס
     * * `PM` - סנט פייר ומיקלון
     * * `VC` - סנט וינסנט והגרנדינים
     * * `ST` - סאו טומה ופרינסיפה
     * * `SJ` - סוולברד ויאן מאיין
     * * `TC` - איי טורקס וקאיקוס
     * * `IO` - הטריטוריה הבריטית באוקיינוס ההודי
     * * `TF` - שטחים דרומיים של צרפת
     * * `HM` - האי הרד ואיי מקדונלד
     * * `UM` - ארצות הברית איי מינור
     * * `MF` - סנט מרטין (החלק הצרפתי)
     * * `SX` - סינט מארטן (חלק הולנדי)
     * * `VI` - איי הבתולה (ארה"ב)
     * * `CC` - איי קוקוס (קילינג)
     * * `VG` - איי הבתולה (בריטניה)
     * * `FK` - איי פוקלנד [מאלווינס]
     * * `GS` - דרום ג'ורג'יה ואיי סנדוויץ 'הדרומיים
     * * `CD` - קונגו (הרפובליקה הדמוקרטית של)
     * * `FM` - מיקרונזיה (המדינות המאוחדות של)
     * * `SH` - סנט הלנה, עלייתה וטריסטן דה קונחה
     * * `DZ` - אלג'יריה
     * * `AZ` - אזרבייג'ן
     * * `TD` - צ'אד
     * * `CL` - צ'ילה
     * * `CZ` - צ'כיה
     * * `DJ` - ג'יבוטי
     * * `JM` - ג'מייקה
     * * `JE` - ג'רזי
     * * `NE` - ניז'ר
     * * `TJ` - טג'יקיסטן
     * * `GW` - גינאה-ביסאו
     * * `TL` - טימור-לסטה
     */
    country: CountryEnum
    country_name: string
    /**
     * מטבע
     * @default "NIS"
     */
    currency?: CurrencyEnum
    id: number
    /**
     * מע״מ
     * לדוגמא - על מנת להזין 17% מעמ יש להזין 1.17
     * @format decimal
     * @pattern ^-?\d{0,2}(?:\.\d{0,2})?$
     */
    vat: string
}

export interface PaymentStateRequest {
    /**
     * * `AF` - אפגניסטן
     * * `AX` - אולנד
     * * `AL` - אלבניה
     * * `AD` - אנדורה
     * * `AO` - אנגולה
     * * `AI` - אנגווילה
     * * `AQ` - אנטארקטיקה
     * * `AR` - ארגנטינה
     * * `AM` - ארמניה
     * * `AW` - ארובה
     * * `AU` - אוסטרליה
     * * `AT` - אוסטריה
     * * `BH` - בחריין
     * * `BD` - בנגלדש
     * * `BB` - ברבדוס
     * * `BY` - בלרוס
     * * `BE` - בלגיה
     * * `BZ` - בליז
     * * `BJ` - בנין
     * * `BM` - ברמודה
     * * `BT` - בותן
     * * `BO` - בוליביה
     * * `BW` - בוטסואנה
     * * `BR` - ברזיל
     * * `BN` - ברונאי
     * * `BG` - בלוגריה
     * * `BI` - בורונדי
     * * `KH` - קמבודיה
     * * `CM` - קמרון
     * * `CA` - קנדה
     * * `CN` - סין
     * * `CO` - קולומביה
     * * `KM` - קומורו
     * * `CG` - קונגו
     * * `HR` - קרואטיה
     * * `CU` - קובה
     * * `CW` - קוראסאו
     * * `CY` - קפריסין
     * * `DK` - דנמרק
     * * `DM` - דומיניקה
     * * `EC` - אקוודור
     * * `EG` - מצרים
     * * `ER` - אריתריאה
     * * `EE` - אסטוניה
     * * `SZ` - סווזילנד
     * * `ET` - אתיופיה
     * * `FJ` - פיגי
     * * `FI` - פינלנד
     * * `FR` - צרפת
     * * `GA` - גבון
     * * `GM` - גמביה
     * * `GE` - גרוזיה
     * * `DE` - גרמניה
     * * `GH` - גאנה
     * * `GI` - גיברלטר
     * * `GR` - יוון
     * * `GL` - גרינלנד
     * * `GD` - גרנדה
     * * `GP` - גוואדלופ
     * * `GU` - גואם
     * * `GT` - גואטמלה
     * * `GG` - גרנזי
     * * `GN` - גינאה
     * * `GY` - גויאנה
     * * `HT` - האיטי
     * * `HN` - הונדורס
     * * `HU` - הונגריה
     * * `IS` - איסלנד
     * * `IN` - הודו
     * * `ID` - אינדונזיה
     * * `IR` - אירן
     * * `IQ` - עירק
     * * `IE` - אירלנד
     * * `IL` - ישראל
     * * `IT` - איטליה
     * * `JP` - יפן
     * * `JO` - ירדן
     * * `KZ` - קזחסטן
     * * `KE` - קניה
     * * `KI` - קיריבטי
     * * `KW` - כווית
     * * `KG` - קירגיזסטן
     * * `LA` - לאוס
     * * `LV` - לטביה
     * * `LB` - לבנון
     * * `LS` - לסוטו
     * * `LR` - ליבריה
     * * `LY` - לוב
     * * `LI` - ליכטנשטיין
     * * `LT` - ליטא
     * * `LU` - לוקסמבורג
     * * `MO` - מקאו
     * * `MG` - מדגסקר
     * * `MW` - מלאווי
     * * `MY` - מלזיה
     * * `ML` - מאלי
     * * `MT` - מלטה
     * * `MQ` - מרטיניק
     * * `MR` - מאוריטניה
     * * `MU` - מאוריציוס
     * * `YT` - מיוט
     * * `MX` - מקסיקו
     * * `MD` - מולדובה
     * * `MC` - מונקו
     * * `MN` - מונגוליה
     * * `ME` - מונטנגרו
     * * `MS` - מונסראט
     * * `MA` - מרוקו
     * * `MZ` - מוזמביק
     * * `MM` - מיאנמר
     * * `NA` - נמיביה
     * * `NR` - נאורו
     * * `NP` - נפאל
     * * `NL` - הולנד
     * * `NI` - ניקרגואה
     * * `NG` - ניגריה
     * * `NU` - ניואה
     * * `MK` - מקדוניה
     * * `NO` - נורבגיה
     * * `OM` - עומאן
     * * `PK` - פקיסטן
     * * `PS` - פלסטין
     * * `PA` - פנמה
     * * `PY` - פרגוואי
     * * `PE` - פרו
     * * `PH` - פיליפינים
     * * `PL` - פולין
     * * `PT` - פורטוגל
     * * `QA` - קטאר
     * * `RE` - ראוניון
     * * `RO` - רומניה
     * * `RU` - רוסיה
     * * `RW` - רואנדה
     * * `WS` - סמואה
     * * `SN` - סנגל
     * * `RS` - סרביה
     * * `SG` - סינגפור
     * * `SK` - סלובקיה
     * * `SI` - סלובניה
     * * `SO` - סומליה
     * * `ES` - ספרד
     * * `SD` - סודאן
     * * `SR` - סורינאם
     * * `SE` - שבדיה
     * * `CH` - שווייץ
     * * `SY` - סוריה
     * * `TW` - טאיוואן
     * * `TZ` - טנזניה
     * * `TH` - תאילנד
     * * `TG` - טוגו
     * * `TK` - טוקלאו
     * * `TO` - טונגה
     * * `TN` - טוניסיה
     * * `TR` - טורקיה
     * * `TM` - טורקמניסטן
     * * `TV` - טובאלו
     * * `UG` - אוגנדה
     * * `UA` - אוקראינה
     * * `GB` - בריטניה
     * * `UY` - אורוגואי
     * * `UZ` - אוזבקיסטן
     * * `VU` - ונואטו
     * * `VE` - ונצואלה
     * * `VN` - ויאטנם
     * * `YE` - תימן
     * * `ZM` - זמביה
     * * `ZW` - זימבבואה
     * * `AS` - סמואה האמריקנית
     * * `AG` - אנטיגואה וברבודה
     * * `BS` - איי בהאמה
     * * `BQ` - הקריביים ההולנדיים
     * * `BA` - בוסניה והרצגובינה
     * * `BV` - האי בווה
     * * `BF` - בורקינה פאסו
     * * `CV` - כף ורדה
     * * `KY` - איי קיימן
     * * `CK` - איי קוק
     * * `CR` - קוסטה ריקה
     * * `CI` - חוף השנהב
     * * `DO` - הרפובליקה הדומיניקנית
     * * `SV` - אל סלוודור
     * * `GQ` - גינאה המשוונית
     * * `FO` - איי פארו
     * * `GF` - גיאנה הצרפתית
     * * `PF` - פולינזיה הצרפתית
     * * `VA` - הכס הקדוש
     * * `HK` - הונג קונג
     * * `IM` - האי מאן
     * * `KP` - קוריאה הצפונית
     * * `KR` - קוריאה הדרומית
     * * `MV` - האיים המלדיביים
     * * `MH` - איי מרשל
     * * `NC` - קלדוניה החדשה
     * * `NZ` - ניו זילנד
     * * `NF` - האי נורפוק
     * * `PW` - איי פלאו
     * * `PN` - איי פיטקיירן
     * * `PR` - פורטו ריקו
     * * `BL` - סנט ברתלמי
     * * `LC` - סנט לוסיה
     * * `SM` - סן מרינו
     * * `SA` - ערב הסעודית
     * * `SC` - איי סיישל
     * * `SL` - סיירה לאונה
     * * `SB` - איי שלמה
     * * `ZA` - דרום אפריקה
     * * `SS` - דרום סודאן
     * * `LK` - סרי לנקה
     * * `TT` - טרינידד וטובגו
     * * `AE` - איחוד האמירויות
     * * `US` - ארצות הברית
     * * `WF` - וואליס ופוטונה
     * * `EH` - סהרה המערבית
     * * `CF` - הרפובליקה המרכז אפריקאית
     * * `CX` - אי חג המולד
     * * `MP` - איי מריאנה הצפוניים
     * * `PG` - פפואה גינאה החדשה
     * * `KN` - סנט קיטס ונוויס
     * * `PM` - סנט פייר ומיקלון
     * * `VC` - סנט וינסנט והגרנדינים
     * * `ST` - סאו טומה ופרינסיפה
     * * `SJ` - סוולברד ויאן מאיין
     * * `TC` - איי טורקס וקאיקוס
     * * `IO` - הטריטוריה הבריטית באוקיינוס ההודי
     * * `TF` - שטחים דרומיים של צרפת
     * * `HM` - האי הרד ואיי מקדונלד
     * * `UM` - ארצות הברית איי מינור
     * * `MF` - סנט מרטין (החלק הצרפתי)
     * * `SX` - סינט מארטן (חלק הולנדי)
     * * `VI` - איי הבתולה (ארה"ב)
     * * `CC` - איי קוקוס (קילינג)
     * * `VG` - איי הבתולה (בריטניה)
     * * `FK` - איי פוקלנד [מאלווינס]
     * * `GS` - דרום ג'ורג'יה ואיי סנדוויץ 'הדרומיים
     * * `CD` - קונגו (הרפובליקה הדמוקרטית של)
     * * `FM` - מיקרונזיה (המדינות המאוחדות של)
     * * `SH` - סנט הלנה, עלייתה וטריסטן דה קונחה
     * * `DZ` - אלג'יריה
     * * `AZ` - אזרבייג'ן
     * * `TD` - צ'אד
     * * `CL` - צ'ילה
     * * `CZ` - צ'כיה
     * * `DJ` - ג'יבוטי
     * * `JM` - ג'מייקה
     * * `JE` - ג'רזי
     * * `NE` - ניז'ר
     * * `TJ` - טג'יקיסטן
     * * `GW` - גינאה-ביסאו
     * * `TL` - טימור-לסטה
     */
    country: CountryEnum
    /**
     * מטבע
     * @default "NIS"
     */
    currency?: CurrencyEnum
    /**
     * מע״מ
     * לדוגמא - על מנת להזין 17% מעמ יש להזין 1.17
     * @format decimal
     * @pattern ^-?\d{0,2}(?:\.\d{0,2})?$
     */
    vat: string
}

export interface Plan {
    /**
     * צבע ראשון
     * @maxLength 200
     */
    color1: string
    /**
     * צבע שני
     * @maxLength 200
     */
    color2: string
    /**
     * תיאור
     * @maxLength 120
     */
    description: string
    id: number
    /**
     * האם לפרסם
     * @default false
     */
    is_published?: boolean
    /**
     * סדר
     * @min -2147483648
     * @max 2147483647
     */
    order?: number | null
    plan: Tariff
    /**
     * תת כותרת
     * @maxLength 200
     */
    subtitle?: string | null
    /**
     * כותרת
     * @maxLength 200
     */
    title?: string | null
}

export interface PlanFeature {
    /**
     * תכולה
     * @maxLength 200
     */
    feature: string
    id: number
}

export interface Pool {
    /** הערות */
    comments?: string | null
    /**
     * מועד יצירה
     * @format date-time
     */
    created_at: string
    id: number
    /**
     * שם
     * @maxLength 200
     */
    name: string
    organization_branch?: number
    organization_branch_name: string
    /**
     * כתובת הבריכה
     * @maxLength 30
     */
    pool_address?: string | null
    /**
     * תמונה של הבריכה
     * @format uri
     */
    pool_image?: string | null
    pool_type: PoolType
    /**
     * מועד עידכון
     * @format date-time
     */
    updated_at: string
}

export interface PoolLogReport {
    /** הערות */
    comments?: string | null
    /**
     * מועד יצירה
     * @format date-time
     */
    created_at: string
    /**
     * כלור חופשי
     * ppm
     * @format decimal
     * @pattern ^-?\d{0,6}(?:\.\d{0,2})?$
     */
    free_chlorine?: string | null
    /**
     * כלור כללי
     * ppm
     * @format decimal
     * @pattern ^-?\d{0,6}(?:\.\d{0,2})?$
     */
    general_chlorine?: string | null
    id: number
    /**
     * יש שימוש בחומצה ציאנורית
     * @default false
     */
    is_using_cyanuric_acid?: boolean | null
    /**
     * יש שימוש בהלוברום
     * @default false
     */
    is_using_halobrom?: boolean | null
    /**
     * עכירות
     * NTU
     * @format decimal
     * @pattern ^-?\d{0,6}(?:\.\d{0,2})?$
     */
    opacity?: string | null
    organization_branch: string
    organization_branch_name: string
    /**
     * ערך הגבה
     * pH
     * @format decimal
     * @pattern ^-?\d{0,6}(?:\.\d{0,2})?$
     */
    ph?: string | null
    /** בריכה */
    pool?: number
    /**
     * תאריך הבדיקה של הבריכה
     * @format date-time
     */
    pool_log_date: string
    pool_name: string
    pool_type: string
    /**
     * רדוקס
     * @format decimal
     * @pattern ^-?\d{0,7}(?:\.\d{0,3})?$
     */
    redox?: string | null
    signature: string
    signer_data: PublicUser
    /**
     * טמפרטורה
     * C°
     * @format decimal
     * @pattern ^-?\d{0,6}(?:\.\d{0,2})?$
     */
    temperature?: string | null
    /**
     * כלור קשור
     * ppm
     * @format decimal
     * @pattern ^-?\d{0,6}(?:\.\d{0,2})?$
     */
    tied_chlorine?: string | null
    /**
     * מועד עידכון
     * @format date-time
     */
    updated_at: string
}

export interface PoolLogReportAttachment {
    /**
     * שם הנספח
     * @maxLength 200
     */
    attachment_name: string
    /**
     * קישור לנספח
     * @format uri
     */
    attachment_url: string
    id: number
    /** דוח יומן בריכה */
    pool_log_report: number
}

export interface PoolLogReportAttachmentRequest {
    /**
     * שם הנספח
     * @minLength 1
     * @maxLength 200
     */
    attachment_name: string
    /**
     * קישור לנספח
     * @format binary
     */
    attachment_url: File
    /** דוח יומן בריכה */
    pool_log_report: number
}

export interface PoolLogReportRequest {
    /** הערות */
    comments?: string | null
    /**
     * כלור חופשי
     * ppm
     * @format decimal
     * @pattern ^-?\d{0,6}(?:\.\d{0,2})?$
     */
    free_chlorine?: string | null
    /**
     * כלור כללי
     * ppm
     * @format decimal
     * @pattern ^-?\d{0,6}(?:\.\d{0,2})?$
     */
    general_chlorine?: string | null
    /**
     * יש שימוש בחומצה ציאנורית
     * @default false
     */
    is_using_cyanuric_acid?: boolean | null
    /**
     * יש שימוש בהלוברום
     * @default false
     */
    is_using_halobrom?: boolean | null
    /**
     * עכירות
     * NTU
     * @format decimal
     * @pattern ^-?\d{0,6}(?:\.\d{0,2})?$
     */
    opacity?: string | null
    /**
     * ערך הגבה
     * pH
     * @format decimal
     * @pattern ^-?\d{0,6}(?:\.\d{0,2})?$
     */
    ph?: string | null
    /** בריכה */
    pool?: number
    /**
     * תאריך הבדיקה של הבריכה
     * @format date-time
     */
    pool_log_date: string
    /**
     * רדוקס
     * @format decimal
     * @pattern ^-?\d{0,7}(?:\.\d{0,3})?$
     */
    redox?: string | null
    /** @minLength 1 */
    signature: string
    signer?: number | null
    /**
     * טמפרטורה
     * C°
     * @format decimal
     * @pattern ^-?\d{0,6}(?:\.\d{0,2})?$
     */
    temperature?: string | null
    /**
     * כלור קשור
     * ppm
     * @format decimal
     * @pattern ^-?\d{0,6}(?:\.\d{0,2})?$
     */
    tied_chlorine?: string | null
}

export interface PoolRequest {
    /** הערות */
    comments?: string | null
    /**
     * שם
     * @minLength 1
     * @maxLength 200
     */
    name: string
    organization_branch?: number
    /**
     * כתובת הבריכה
     * @maxLength 30
     */
    pool_address?: string | null
    /**
     * תמונה של הבריכה
     * @format binary
     */
    pool_image?: File | null
    pool_type_id: number
}

export interface PoolType {
    id: number
    /**
     * שם לועזי
     * @maxLength 20
     */
    name_english?: string | null
    /**
     * שם עברי
     * @maxLength 20
     */
    name_hebrew?: string | null
}

export interface PoolTypeRequest {
    /**
     * שם לועזי
     * @minLength 1
     * @maxLength 20
     */
    name_english?: string | null
    /**
     * שם עברי
     * @minLength 1
     * @maxLength 20
     */
    name_hebrew?: string | null
}

export interface PublicUser {
    /**
     * שם פרטי
     * @maxLength 150
     */
    first_name: string
    id: number
    /**
     * שם משפחה
     * @maxLength 150
     */
    last_name?: string
    /**
     * מספר טלפון
     * @maxLength 128
     */
    phone?: string | null
    /**
     * תמונת משתמש
     * @format uri
     */
    user_photo?: string | null
}

export interface PublicUserRequest {
    /**
     * שם פרטי
     * @minLength 1
     * @maxLength 150
     */
    first_name: string
    /**
     * שם משפחה
     * @maxLength 150
     */
    last_name?: string
    /**
     * מספר טלפון
     * @maxLength 128
     */
    phone?: string | null
    /**
     * תמונת משתמש
     * @format binary
     */
    user_photo?: File | null
}

export interface Question {
    /**
     * תשובה
     * @maxLength 200
     */
    answer: string
    id: number
    /**
     * סדר
     * @min -2147483648
     * @max 2147483647
     */
    order?: number | null
    /**
     * שאלה
     * @maxLength 200
     */
    question: string
}

export interface Recommendation {
    /**
     * תוכן
     * @maxLength 500
     */
    content: string
    id: number
    /**
     * תמונה
     * @format uri
     */
    image?: string | null
    /**
     * ממליץ
     * @maxLength 200
     */
    recommender: string
    /**
     * כותרת
     * @maxLength 200
     */
    title: string
}

export enum RoleEnum {
    OrganizationManager = 'organization_manager',
    PoolsManager = 'pools_manager',
    Operator = 'operator',
}

export interface Subscription {
    /**
     * מספר חשבון בנק
     * @maxLength 200
     */
    bank_account_number?: string | null
    /**
     * מספר סניף בנק
     * @maxLength 200
     */
    bank_branch_number?: string | null
    /**
     * מספר בנק
     * @maxLength 200
     */
    bank_number?: string | null
    /**
     * מועד יצירה
     * @format date-time
     */
    created_at: string
    /**
     * תוקף אשראי - חודש
     * @maxLength 20
     */
    credit_card_expiry_month?: string | null
    /**
     * תוקף אשראי - שנה
     * @maxLength 20
     */
    credit_card_expiry_year?: string | null
    days_until_end_of_subscription: number
    /** @format date */
    end_of_subscription_date: string
    /**
     * ביטל חידוש אוטומטי
     * @default false
     */
    has_cancelled?: boolean
    id: number
    /**
     * תשלומים
     * @min -2147483648
     * @max 2147483647
     * @default 1
     */
    installments?: number
    /**
     * חודש לאחרונה בתאריך
     * @format date
     */
    last_renewed_at?: string | null
    /**
     * מנוי נעול
     * מנויים שלא חידשו מנוי והגיע יום הסיום שלהם.
     * @default false
     */
    locked?: boolean
    /** מתחם בריכות */
    organization_branch: number
    plan: Tariff
    /** משלם המנוי */
    subscriber?: number | null
    /**
     * מזהה מנוי
     * @maxLength 200
     */
    subscription_id?: string | null
    /**
     * מועד עידכון
     * @format date-time
     */
    updated_at: string
}

export interface SubscriptionRequest {
    /**
     * מספר חשבון בנק
     * @maxLength 200
     */
    bank_account_number?: string | null
    /**
     * מספר סניף בנק
     * @maxLength 200
     */
    bank_branch_number?: string | null
    /**
     * מספר בנק
     * @maxLength 200
     */
    bank_number?: string | null
    /**
     * תוקף אשראי - חודש
     * @maxLength 20
     */
    credit_card_expiry_month?: string | null
    /**
     * תוקף אשראי - שנה
     * @maxLength 20
     */
    credit_card_expiry_year?: string | null
    /**
     * ביטל חידוש אוטומטי
     * @default false
     */
    has_cancelled?: boolean
    /**
     * תשלומים
     * @min -2147483648
     * @max 2147483647
     * @default 1
     */
    installments?: number
    /**
     * חודש לאחרונה בתאריך
     * @format date
     */
    last_renewed_at?: string | null
    /**
     * מנוי נעול
     * מנויים שלא חידשו מנוי והגיע יום הסיום שלהם.
     * @default false
     */
    locked?: boolean
    /** מתחם בריכות */
    organization_branch: number
    /** משלם המנוי */
    subscriber?: number | null
    /**
     * מזהה מנוי
     * @maxLength 200
     */
    subscription_id?: string | null
}

/**
 * * `LITRE` - ליטר
 * * `KILOGRAM` - קילוגרם
 */
export enum SubstanceAmountTypeEnum {
    LITRE = 'LITRE',
    KILOGRAM = 'KILOGRAM',
}

/**
 * * `MIXTURE` - מזיגה
 * * `BUCKETS` - דליים
 * * `JERRYCANS` - ג׳ריקנים
 */
export enum SubstanceRetrievalMethodEnum {
    MIXTURE = 'MIXTURE',
    BUCKETS = 'BUCKETS',
    JERRYCANS = 'JERRYCANS',
}

export interface Tariff {
    /**
     * תעריף פעיל
     * @default false
     */
    active_tariff?: boolean
    /**
     * מחיר
     * @format decimal
     * @default "0.00"
     * @pattern ^-?\d{0,18}(?:\.\d{0,2})?$
     */
    cost?: string
    currency: Record<string, any>
    id: number
    /**
     * מספר בריכות מקסימלי
     * @min -2147483648
     * @max 2147483647
     */
    max_pools: number
    /**
     * שם החבילה
     * @maxLength 200
     * @default "subscription"
     */
    name?: string
    /**
     * סדר
     * @min 0
     * @max 2147483647
     */
    order?: number | null
    /**
     * חודש התחלת מנוי
     * @min -2147483648
     * @max 2147483647
     * @default 1
     */
    start_month?: number
    /** מדינה */
    state?: number | null
    /**
     * אורך מנוי (חודשים)
     * @min -2147483648
     * @max 2147483647
     * @default 12
     */
    subscription_length?: number
}

export interface TariffRequest {
    /**
     * תעריף פעיל
     * @default false
     */
    active_tariff?: boolean
    /**
     * מחיר
     * @format decimal
     * @default "0.00"
     * @pattern ^-?\d{0,18}(?:\.\d{0,2})?$
     */
    cost?: string
    /**
     * מספר בריכות מקסימלי
     * @min -2147483648
     * @max 2147483647
     */
    max_pools: number
    /**
     * שם החבילה
     * @minLength 1
     * @maxLength 200
     * @default "subscription"
     */
    name?: string
    /**
     * סדר
     * @min 0
     * @max 2147483647
     */
    order?: number | null
    /**
     * חודש התחלת מנוי
     * @min -2147483648
     * @max 2147483647
     * @default 1
     */
    start_month?: number
    /** מדינה */
    state?: number | null
    /**
     * אורך מנוי (חודשים)
     * @min -2147483648
     * @max 2147483647
     * @default 12
     */
    subscription_length?: number
}

export interface TokenObtainPair {
    access: string
    refresh: string
}

export interface TokenObtainPairRequest {
    /** @minLength 1 */
    password: string
    /** @minLength 1 */
    username: string
}

export interface TokenRefresh {
    access: string
}

export interface TokenRefreshRequest {
    /** @minLength 1 */
    refresh: string
}

export interface ToxicReport {
    /** הערות */
    comments?: string | null
    /**
     * מועד יצירה
     * @format date-time
     */
    created_at: string
    id: number
    linked_attachments: string
    /** מתחם בריכות */
    organization_branch: number
    organization_branch_name: string
    /** חתימה */
    signature?: string | null
    /**
     * שם מקבל החומר
     * @maxLength 50
     */
    substance_receiver_name: string
    /**
     * תאריך דוח הרעלים
     * @format date-time
     */
    toxic_report_date: string
    toxic_substance_lines: ToxicSubstanceLine[]
    /**
     * מועד עידכון
     * @format date-time
     */
    updated_at: string
}

export interface ToxicReportRequest {
    attachments?: File[]
    /** הערות */
    comments?: string | null
    /** מתחם בריכות */
    organization_branch: number
    /**
     * חתימה
     * @minLength 1
     */
    signature?: string | null
    /**
     * שם מקבל החומר
     * @minLength 1
     * @maxLength 50
     */
    substance_receiver_name: string
    /**
     * תאריך דוח הרעלים
     * @format date-time
     */
    toxic_report_date: string
    toxic_substance_lines_create: ToxicSubstanceLineCreateRequest[]
}

export interface ToxicSubstance {
    /**
     * מספר cas
     * @maxLength 20
     */
    cas?: string | null
    /**
     * קוד חירום
     * @maxLength 20
     */
    emergency_code: string
    /**
     * שם לועזי
     * @maxLength 100
     */
    english_name: string
    /**
     * קבוצת סיכון
     * @maxLength 20
     */
    hazard_group: string
    /**
     * שם עברי
     * @maxLength 100
     */
    hebrew_name: string
    id: number
    /**
     * סדר
     * @min 0
     * @max 2147483647
     */
    order?: number | null
    /**
     * מספר או"ם
     * @maxLength 20
     */
    un_number: string
    /**
     * גלוי
     * @default true
     */
    visible?: boolean
}

export interface ToxicSubstanceLine {
    id: number
    substance: ToxicSubstance
    /**
     * כמות החומר
     * @min 0
     * @max 2147483647
     */
    substance_amount: number
    /** סוג כמות החומר */
    substance_amount_type: SubstanceAmountTypeEnum
    substance_en_name: string
    substance_he_name: string
    /** סוג החומר */
    substance_retrieval_method?: SubstanceRetrievalMethodEnum | NullEnum | null
    /**
     * מספר סידורי של החומר
     * @maxLength 200
     */
    substance_serial_number: string
    /**
     * שם הספק
     * @maxLength 50
     */
    supplier_name: string
    /** דוח פנקס רעלים */
    toxic_report: number
}

export interface ToxicSubstanceLineCreate {
    /** שם החומר */
    substance?: number | null
    /**
     * כמות החומר
     * @min 0
     * @max 2147483647
     */
    substance_amount: number
    /** סוג כמות החומר */
    substance_amount_type: SubstanceAmountTypeEnum
    /** סוג החומר */
    substance_retrieval_method?: SubstanceRetrievalMethodEnum | NullEnum | null
    /**
     * מספר סידורי של החומר
     * @maxLength 200
     */
    substance_serial_number: string
    /**
     * שם הספק
     * @maxLength 50
     */
    supplier_name: string
}

export interface ToxicSubstanceLineCreateRequest {
    /** שם החומר */
    substance?: number | null
    /**
     * כמות החומר
     * @min 0
     * @max 2147483647
     */
    substance_amount: number
    /** סוג כמות החומר */
    substance_amount_type: SubstanceAmountTypeEnum
    /** סוג החומר */
    substance_retrieval_method?: SubstanceRetrievalMethodEnum | NullEnum | null
    /**
     * מספר סידורי של החומר
     * @minLength 1
     * @maxLength 200
     */
    substance_serial_number: string
    /**
     * שם הספק
     * @minLength 1
     * @maxLength 50
     */
    supplier_name: string
}

export interface ToxicSubstanceLineRequest {
    /**
     * כמות החומר
     * @min 0
     * @max 2147483647
     */
    substance_amount: number
    /** סוג כמות החומר */
    substance_amount_type: SubstanceAmountTypeEnum
    /** סוג החומר */
    substance_retrieval_method?: SubstanceRetrievalMethodEnum | NullEnum | null
    /**
     * מספר סידורי של החומר
     * @minLength 1
     * @maxLength 200
     */
    substance_serial_number: string
    /**
     * שם הספק
     * @minLength 1
     * @maxLength 50
     */
    supplier_name: string
    /** דוח פנקס רעלים */
    toxic_report: number
}

export interface ToxicSubstanceRequest {
    /**
     * מספר cas
     * @maxLength 20
     */
    cas?: string | null
    /**
     * קוד חירום
     * @minLength 1
     * @maxLength 20
     */
    emergency_code: string
    /**
     * שם לועזי
     * @minLength 1
     * @maxLength 100
     */
    english_name: string
    /**
     * קבוצת סיכון
     * @minLength 1
     * @maxLength 20
     */
    hazard_group: string
    /**
     * שם עברי
     * @minLength 1
     * @maxLength 100
     */
    hebrew_name: string
    /**
     * סדר
     * @min 0
     * @max 2147483647
     */
    order?: number | null
    /**
     * מספר או"ם
     * @minLength 1
     * @maxLength 20
     */
    un_number: string
    /**
     * גלוי
     * @default true
     */
    visible?: boolean
}

export interface TwoFaRequest {
    /** @minLength 1 */
    code: string
    /** @minLength 1 */
    phone: string
}

export interface TwoFaResponse {
    access: string
    refresh: string
}

export interface UserCreate {
    /**
     * כתובת דוא"ל
     * @format email
     * @maxLength 254
     */
    email?: string
    /**
     * שם פרטי
     * @maxLength 150
     */
    first_name: string
    id: number
    /**
     * שם משפחה
     * @maxLength 150
     */
    last_name?: string
    /**
     * מספר טלפון
     * @maxLength 128
     */
    phone?: string | null
    /**
     * תמונת משתמש
     * @format uri
     */
    user_photo?: string | null
}

export interface UserCreateRequest {
    /**
     * כתובת דוא"ל
     * @format email
     * @maxLength 254
     */
    email?: string
    /**
     * שם פרטי
     * @minLength 1
     * @maxLength 150
     */
    first_name: string
    /**
     * שם משפחה
     * @maxLength 150
     */
    last_name?: string
    /**
     * מספר טלפון
     * @maxLength 128
     */
    phone?: string | null
    /**
     * תמונת משתמש
     * @format binary
     */
    user_photo?: File | null
}

export interface UserDetails {
    /**
     * כתובת דוא"ל
     * @format email
     * @maxLength 254
     */
    email?: string
    /**
     * שם פרטי
     * @maxLength 150
     */
    first_name: string
    full_name: string
    id: number
    /**
     * שם משפחה
     * @maxLength 150
     */
    last_name?: string
    manager_in_organization_branches: string
    operator_in_organization_branch: string
    /**
     * מספר טלפון
     * @maxLength 128
     */
    phone?: string | null
    role: RoleEnum
    user_manager_in_organization_branch: string
    /**
     * תמונת משתמש
     * @format uri
     */
    user_photo?: string | null
}

export interface UserDetailsRequest {
    /**
     * כתובת דוא"ל
     * @format email
     * @maxLength 254
     */
    email?: string
    /**
     * שם פרטי
     * @minLength 1
     * @maxLength 150
     */
    first_name: string
    /**
     * שם משפחה
     * @maxLength 150
     */
    last_name?: string
    /**
     * מספר טלפון
     * @maxLength 128
     */
    phone?: string | null
    /**
     * תמונת משתמש
     * @format binary
     */
    user_photo?: File | null
}

import type { AxiosInstance, AxiosRequestConfig, HeadersDefaults, ResponseType } from 'axios'
import axios from 'axios'

export type QueryParamsType = Record<string | number, any>

export interface FullRequestParams extends Omit<AxiosRequestConfig, 'data' | 'params' | 'url' | 'responseType'> {
    /** set parameter to `true` for call `securityWorker` for this request */
    secure?: boolean
    /** request path */
    path: string
    /** content type of request body */
    type?: ContentType
    /** query params */
    query?: QueryParamsType
    /** format of response (i.e. response.json() -> format: "json") */
    format?: ResponseType
    /** request body */
    body?: unknown
}

export type RequestParams = Omit<FullRequestParams, 'body' | 'method' | 'query' | 'path'>

export interface ApiConfig<SecurityDataType = unknown> extends Omit<AxiosRequestConfig, 'data' | 'cancelToken'> {
    securityWorker?: (
        securityData: SecurityDataType | null
    ) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void
    secure?: boolean
    format?: ResponseType
}

export enum ContentType {
    Json = 'application/json',
    FormData = 'multipart/form-data',
    UrlEncoded = 'application/x-www-form-urlencoded',
    Text = 'text/plain',
}

export class HttpClient<SecurityDataType = unknown> {
    public instance: AxiosInstance
    private securityData: SecurityDataType | null = null
    private securityWorker?: ApiConfig<SecurityDataType>['securityWorker']
    private secure?: boolean
    private format?: ResponseType

    constructor({ securityWorker, secure, format, ...axiosConfig }: ApiConfig<SecurityDataType> = {}) {
        this.instance = axios.create({ ...axiosConfig, baseURL: axiosConfig.baseURL || '' })
        this.secure = secure
        this.format = format
        this.securityWorker = securityWorker
    }

    public setSecurityData = (data: SecurityDataType | null) => {
        this.securityData = data
    }

    protected mergeRequestParams(params1: AxiosRequestConfig, params2?: AxiosRequestConfig): AxiosRequestConfig {
        const method = params1.method || (params2 && params2.method)

        return {
            ...this.instance.defaults,
            ...params1,
            ...(params2 || {}),
            headers: {
                ...((method && this.instance.defaults.headers[method.toLowerCase() as keyof HeadersDefaults]) || {}),
                ...(params1.headers || {}),
                ...((params2 && params2.headers) || {}),
            },
        }
    }

    protected stringifyFormItem(formItem: unknown) {
        if (typeof formItem === 'object' && formItem !== null) {
            return JSON.stringify(formItem)
        } else {
            return `${formItem}`
        }
    }

    protected createFormData(input: Record<string, unknown>): FormData {
        if (input instanceof FormData) {
            return input
        }
        return Object.keys(input || {}).reduce((formData, key) => {
            const property = input[key]
            const propertyContent: any[] = property instanceof Array ? property : [property]

            for (const formItem of propertyContent) {
                const isFileType = formItem instanceof Blob || formItem instanceof File
                formData.append(key, isFileType ? formItem : this.stringifyFormItem(formItem))
            }

            return formData
        }, new FormData())
    }

    public request = async <T = any, _E = any>({
        secure,
        path,
        type,
        query,
        format,
        body,
        ...params
    }: FullRequestParams): Promise<T> => {
        const secureParams =
            ((typeof secure === 'boolean' ? secure : this.secure) &&
                this.securityWorker &&
                (await this.securityWorker(this.securityData))) ||
            {}
        const requestParams = this.mergeRequestParams(params, secureParams)
        const responseFormat = format || this.format || undefined

        if (type === ContentType.FormData && body && body !== null && typeof body === 'object') {
            body = this.createFormData(body as Record<string, unknown>)
        }

        if (type === ContentType.Text && body && body !== null && typeof body !== 'string') {
            body = JSON.stringify(body)
        }

        return this.instance
            .request({
                ...requestParams,
                headers: {
                    ...(requestParams.headers || {}),
                    ...(type ? { 'Content-Type': type } : {}),
                },
                params: query,
                responseType: responseFormat,
                data: body,
                url: path,
            })
            .then((response) => response.data)
    }
}

/**
 * @title No title
 * @version 0.0.0
 */
export class Api<SecurityDataType extends unknown> {
    http: HttpClient<SecurityDataType>

    constructor(http: HttpClient<SecurityDataType>) {
        this.http = http
    }

    apiTokenAuth = {
        /**
         * @description Takes a set of user credentials and returns an access and refresh JSON web token pair to prove the authentication of those credentials.
         *
         * @tags api-token-auth
         * @name ApiTokenAuthCreate
         * @request POST:/api-token-auth/
         */
        apiTokenAuthCreate: (data: TokenObtainPairRequest, params: RequestParams = {}) =>
            this.http.request<TokenObtainPair, any>({
                path: `/api-token-auth/`,
                method: 'POST',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),
    }
    apiTokenRefresh = {
        /**
         * @description Takes a refresh type JSON web token and returns an access type JSON web token if the refresh token is valid.
         *
         * @tags api-token-refresh
         * @name ApiTokenRefreshCreate
         * @request POST:/api-token-refresh/
         */
        apiTokenRefreshCreate: (data: TokenRefreshRequest, params: RequestParams = {}) =>
            this.http.request<TokenRefresh, any>({
                path: `/api-token-refresh/`,
                method: 'POST',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),
    }
    api = {
        /**
         * No description
         *
         * @tags api
         * @name ApiActivateRenewalRetrieve
         * @request GET:/api/activate-renewal
         * @secure
         */
        apiActivateRenewalRetrieve: (params: RequestParams = {}) =>
            this.http.request<void, any>({
                path: `/api/activate-renewal`,
                method: 'GET',
                secure: true,
                ...params,
            }),

        /**
         * No description
         *
         * @tags api
         * @name ApiAgreementsCreate
         * @request POST:/api/agreements/
         * @secure
         */
        apiAgreementsCreate: (data: AgreementRequest, params: RequestParams = {}) =>
            this.http.request<Agreement, any>({
                path: `/api/agreements/`,
                method: 'POST',
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags api
         * @name ApiAgreementsDestroy
         * @request DELETE:/api/agreements/{id}/
         * @secure
         */
        apiAgreementsDestroy: (id: number, params: RequestParams = {}) =>
            this.http.request<void, any>({
                path: `/api/agreements/${id}/`,
                method: 'DELETE',
                secure: true,
                ...params,
            }),

        /**
         * No description
         *
         * @tags api
         * @name ApiAgreementsList
         * @request GET:/api/agreements/
         * @secure
         */
        apiAgreementsList: (query: ApiAgreementsListParams, params: RequestParams = {}) =>
            this.http.request<PaginatedAgreementList, any>({
                path: `/api/agreements/`,
                method: 'GET',
                query: query,
                secure: true,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags api
         * @name ApiAgreementsPartialUpdate
         * @request PATCH:/api/agreements/{id}/
         * @secure
         */
        apiAgreementsPartialUpdate: (id: number, data: PatchedAgreementRequest, params: RequestParams = {}) =>
            this.http.request<Agreement, any>({
                path: `/api/agreements/${id}/`,
                method: 'PATCH',
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags api
         * @name ApiAgreementsRetrieve
         * @request GET:/api/agreements/{id}/
         * @secure
         */
        apiAgreementsRetrieve: (id: number, params: RequestParams = {}) =>
            this.http.request<Agreement, any>({
                path: `/api/agreements/${id}/`,
                method: 'GET',
                secure: true,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags api
         * @name ApiAgreementsUpdate
         * @request PUT:/api/agreements/{id}/
         * @secure
         */
        apiAgreementsUpdate: (id: number, data: AgreementRequest, params: RequestParams = {}) =>
            this.http.request<Agreement, any>({
                path: `/api/agreements/${id}/`,
                method: 'PUT',
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags api
         * @name ApiBranchManagersCreate
         * @request POST:/api/branch/{branchId}/managers/
         * @secure
         */
        apiBranchManagersCreate: (branchId: string, data: ManagerListRequest, params: RequestParams = {}) =>
            this.http.request<ManagerList, any>({
                path: `/api/branch/${branchId}/managers/`,
                method: 'POST',
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags api
         * @name ApiBranchManagersDestroy
         * @request DELETE:/api/branch/{branchId}/managers/{id}/
         * @secure
         */
        apiBranchManagersDestroy: (branchId: string, id: number, params: RequestParams = {}) =>
            this.http.request<void, any>({
                path: `/api/branch/${branchId}/managers/${id}/`,
                method: 'DELETE',
                secure: true,
                ...params,
            }),

        /**
         * No description
         *
         * @tags api
         * @name ApiBranchManagersList
         * @request GET:/api/branch/{branchId}/managers/
         * @secure
         */
        apiBranchManagersList: ({ branchId, ...query }: ApiBranchManagersListParams, params: RequestParams = {}) =>
            this.http.request<PaginatedManagerListList, any>({
                path: `/api/branch/${branchId}/managers/`,
                method: 'GET',
                query: query,
                secure: true,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags api
         * @name ApiBranchManagersPartialUpdate
         * @request PATCH:/api/branch/{branchId}/managers/{id}/
         * @secure
         */
        apiBranchManagersPartialUpdate: (
            branchId: string,
            id: number,
            data: PatchedManagerListRequest,
            params: RequestParams = {}
        ) =>
            this.http.request<ManagerList, any>({
                path: `/api/branch/${branchId}/managers/${id}/`,
                method: 'PATCH',
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags api
         * @name ApiBranchManagersRetrieve
         * @request GET:/api/branch/{branchId}/managers/{id}/
         * @secure
         */
        apiBranchManagersRetrieve: (branchId: string, id: number, params: RequestParams = {}) =>
            this.http.request<ManagerList, any>({
                path: `/api/branch/${branchId}/managers/${id}/`,
                method: 'GET',
                secure: true,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags api
         * @name ApiBranchManagersUpdate
         * @request PUT:/api/branch/{branchId}/managers/{id}/
         * @secure
         */
        apiBranchManagersUpdate: (branchId: string, id: number, data: ManagerListRequest, params: RequestParams = {}) =>
            this.http.request<ManagerList, any>({
                path: `/api/branch/${branchId}/managers/${id}/`,
                method: 'PUT',
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags api
         * @name ApiBranchOperatorsCreate
         * @request POST:/api/branch/{branchId}/operators/
         * @secure
         */
        apiBranchOperatorsCreate: (branchId: string, data: ManagerListRequest, params: RequestParams = {}) =>
            this.http.request<ManagerList, any>({
                path: `/api/branch/${branchId}/operators/`,
                method: 'POST',
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags api
         * @name ApiBranchOperatorsDestroy
         * @request DELETE:/api/branch/{branchId}/operators/{id}/
         * @secure
         */
        apiBranchOperatorsDestroy: (branchId: string, id: number, params: RequestParams = {}) =>
            this.http.request<void, any>({
                path: `/api/branch/${branchId}/operators/${id}/`,
                method: 'DELETE',
                secure: true,
                ...params,
            }),

        /**
         * No description
         *
         * @tags api
         * @name ApiBranchOperatorsList
         * @request GET:/api/branch/{branchId}/operators/
         * @secure
         */
        apiBranchOperatorsList: ({ branchId, ...query }: ApiBranchOperatorsListParams, params: RequestParams = {}) =>
            this.http.request<PaginatedManagerListList, any>({
                path: `/api/branch/${branchId}/operators/`,
                method: 'GET',
                query: query,
                secure: true,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags api
         * @name ApiBranchOperatorsPartialUpdate
         * @request PATCH:/api/branch/{branchId}/operators/{id}/
         * @secure
         */
        apiBranchOperatorsPartialUpdate: (
            branchId: string,
            id: number,
            data: PatchedManagerListRequest,
            params: RequestParams = {}
        ) =>
            this.http.request<ManagerList, any>({
                path: `/api/branch/${branchId}/operators/${id}/`,
                method: 'PATCH',
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags api
         * @name ApiBranchOperatorsRetrieve
         * @request GET:/api/branch/{branchId}/operators/{id}/
         * @secure
         */
        apiBranchOperatorsRetrieve: (branchId: string, id: number, params: RequestParams = {}) =>
            this.http.request<ManagerList, any>({
                path: `/api/branch/${branchId}/operators/${id}/`,
                method: 'GET',
                secure: true,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags api
         * @name ApiBranchOperatorsUpdate
         * @request PUT:/api/branch/{branchId}/operators/{id}/
         * @secure
         */
        apiBranchOperatorsUpdate: (
            branchId: string,
            id: number,
            data: ManagerListRequest,
            params: RequestParams = {}
        ) =>
            this.http.request<ManagerList, any>({
                path: `/api/branch/${branchId}/operators/${id}/`,
                method: 'PUT',
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags api
         * @name ApiCancelRenewalRetrieve
         * @request GET:/api/cancel-renewal
         * @secure
         */
        apiCancelRenewalRetrieve: (query: ApiCancelRenewalRetrieveParams, params: RequestParams = {}) =>
            this.http.request<void, any>({
                path: `/api/cancel-renewal`,
                method: 'GET',
                query: query,
                secure: true,
                ...params,
            }),

        /**
         * No description
         *
         * @tags api
         * @name ApiCountriesCreate
         * @request POST:/api/countries/
         * @secure
         */
        apiCountriesCreate: (data: PaymentStateRequest, params: RequestParams = {}) =>
            this.http.request<PaymentState, any>({
                path: `/api/countries/`,
                method: 'POST',
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags api
         * @name ApiCountriesDestroy
         * @request DELETE:/api/countries/{id}/
         * @secure
         */
        apiCountriesDestroy: (id: number, params: RequestParams = {}) =>
            this.http.request<void, any>({
                path: `/api/countries/${id}/`,
                method: 'DELETE',
                secure: true,
                ...params,
            }),

        /**
         * No description
         *
         * @tags api
         * @name ApiCountriesList
         * @request GET:/api/countries/
         * @secure
         */
        apiCountriesList: (query: ApiCountriesListParams, params: RequestParams = {}) =>
            this.http.request<PaginatedPaymentStateList, any>({
                path: `/api/countries/`,
                method: 'GET',
                query: query,
                secure: true,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags api
         * @name ApiCountriesPartialUpdate
         * @request PATCH:/api/countries/{id}/
         * @secure
         */
        apiCountriesPartialUpdate: (id: number, data: PatchedPaymentStateRequest, params: RequestParams = {}) =>
            this.http.request<PaymentState, any>({
                path: `/api/countries/${id}/`,
                method: 'PATCH',
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags api
         * @name ApiCountriesRetrieve
         * @request GET:/api/countries/{id}/
         * @secure
         */
        apiCountriesRetrieve: (id: number, params: RequestParams = {}) =>
            this.http.request<PaymentState, any>({
                path: `/api/countries/${id}/`,
                method: 'GET',
                secure: true,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags api
         * @name ApiCountriesUpdate
         * @request PUT:/api/countries/{id}/
         * @secure
         */
        apiCountriesUpdate: (id: number, data: PaymentStateRequest, params: RequestParams = {}) =>
            this.http.request<PaymentState, any>({
                path: `/api/countries/${id}/`,
                method: 'PUT',
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags api
         * @name ApiE2ESetupCreate
         * @request POST:/api/e2e-setup
         * @secure
         */
        apiE2ESetupCreate: (params: RequestParams = {}) =>
            this.http.request<void, any>({
                path: `/api/e2e-setup`,
                method: 'POST',
                secure: true,
                ...params,
            }),

        /**
         * No description
         *
         * @tags api
         * @name ApiGenerateCreditIframeRetrieve
         * @request GET:/api/generate-credit-iframe
         * @secure
         */
        apiGenerateCreditIframeRetrieve: (query: ApiGenerateCreditIframeRetrieveParams, params: RequestParams = {}) =>
            this.http.request<IframeResponse, any>({
                path: `/api/generate-credit-iframe`,
                method: 'GET',
                query: query,
                secure: true,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags api
         * @name ApiLandingPageList
         * @request GET:/api/landing-page/
         * @secure
         */
        apiLandingPageList: (query: ApiLandingPageListParams, params: RequestParams = {}) =>
            this.http.request<PaginatedLandingPageList, any>({
                path: `/api/landing-page/`,
                method: 'GET',
                query: query,
                secure: true,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags api
         * @name ApiLandingPageRetrieve
         * @request GET:/api/landing-page/{id}/
         * @secure
         */
        apiLandingPageRetrieve: (id: number, params: RequestParams = {}) =>
            this.http.request<LandingPage, any>({
                path: `/api/landing-page/${id}/`,
                method: 'GET',
                secure: true,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags api
         * @name ApiLeadsCreate
         * @request POST:/api/leads/
         * @secure
         */
        apiLeadsCreate: (data: LeadsRequest, params: RequestParams = {}) =>
            this.http.request<Leads, any>({
                path: `/api/leads/`,
                method: 'POST',
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags api
         * @name ApiOrganizationBranchCreate
         * @request POST:/api/organization-branch/
         * @secure
         */
        apiOrganizationBranchCreate: (data: OrganizationBranchRequest, params: RequestParams = {}) =>
            this.http.request<OrganizationBranch, any>({
                path: `/api/organization-branch/`,
                method: 'POST',
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags api
         * @name ApiOrganizationBranchDeleteManagerRolesCreate
         * @request POST:/api/organization-branch/{branchId}/delete_manager_roles/{phone}/
         * @secure
         */
        apiOrganizationBranchDeleteManagerRolesCreate: (
            branchId: number,
            phone: string,
            data: OrganizationBranchRequest,
            params: RequestParams = {}
        ) =>
            this.http.request<OrganizationBranch, any>({
                path: `/api/organization-branch/${branchId}/delete_manager_roles/${phone}/`,
                method: 'POST',
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags api
         * @name ApiOrganizationBranchDestroy
         * @request DELETE:/api/organization-branch/{branchId}/
         * @secure
         */
        apiOrganizationBranchDestroy: (branchId: number, params: RequestParams = {}) =>
            this.http.request<void, any>({
                path: `/api/organization-branch/${branchId}/`,
                method: 'DELETE',
                secure: true,
                ...params,
            }),

        /**
         * No description
         *
         * @tags api
         * @name ApiOrganizationBranchKickOperatorCreate
         * @request POST:/api/organization-branch/{branchId}/kick-operator/{phone}/{language}/
         * @secure
         */
        apiOrganizationBranchKickOperatorCreate: (
            branchId: number,
            language: string,
            phone: string,
            data: OrganizationBranchRequest,
            params: RequestParams = {}
        ) =>
            this.http.request<OrganizationBranch, any>({
                path: `/api/organization-branch/${branchId}/kick-operator/${phone}/${language}/`,
                method: 'POST',
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags api
         * @name ApiOrganizationBranchList
         * @request GET:/api/organization-branch/
         * @secure
         */
        apiOrganizationBranchList: (query: ApiOrganizationBranchListParams, params: RequestParams = {}) =>
            this.http.request<PaginatedOrganizationBranchList, any>({
                path: `/api/organization-branch/`,
                method: 'GET',
                query: query,
                secure: true,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags api
         * @name ApiOrganizationBranchMakeManagerCreate
         * @request POST:/api/organization-branch/{branchId}/make-manager/{phone}/{language}/
         * @secure
         */
        apiOrganizationBranchMakeManagerCreate: (
            branchId: number,
            language: string,
            phone: string,
            params: RequestParams = {}
        ) =>
            this.http.request<void, any>({
                path: `/api/organization-branch/${branchId}/make-manager/${phone}/${language}/`,
                method: 'POST',
                secure: true,
                ...params,
            }),

        /**
         * No description
         *
         * @tags api
         * @name ApiOrganizationBranchMakeOperatorCreate
         * @request POST:/api/organization-branch/{branchId}/make-operator/{phone}/{language}/
         * @secure
         */
        apiOrganizationBranchMakeOperatorCreate: (
            branchId: number,
            language: string,
            phone: string,
            data: OrganizationBranchRequest,
            params: RequestParams = {}
        ) =>
            this.http.request<OrganizationBranch, any>({
                path: `/api/organization-branch/${branchId}/make-operator/${phone}/${language}/`,
                method: 'POST',
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags api
         * @name ApiOrganizationBranchMakeUserManagerCreate
         * @request POST:/api/organization-branch/{branchId}/make-user-manager/{phone}/{language}/
         * @secure
         */
        apiOrganizationBranchMakeUserManagerCreate: (
            branchId: number,
            language: string,
            phone: string,
            params: RequestParams = {}
        ) =>
            this.http.request<void, any>({
                path: `/api/organization-branch/${branchId}/make-user-manager/${phone}/${language}/`,
                method: 'POST',
                secure: true,
                ...params,
            }),

        /**
         * No description
         *
         * @tags api
         * @name ApiOrganizationBranchPartialUpdate
         * @request PATCH:/api/organization-branch/{branchId}/
         * @secure
         */
        apiOrganizationBranchPartialUpdate: (
            branchId: number,
            data: PatchedOrganizationBranchRequest,
            params: RequestParams = {}
        ) =>
            this.http.request<OrganizationBranch, any>({
                path: `/api/organization-branch/${branchId}/`,
                method: 'PATCH',
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags api
         * @name ApiOrganizationBranchRetrieve
         * @request GET:/api/organization-branch/{branchId}/
         * @secure
         */
        apiOrganizationBranchRetrieve: (branchId: number, params: RequestParams = {}) =>
            this.http.request<OrganizationBranch, any>({
                path: `/api/organization-branch/${branchId}/`,
                method: 'GET',
                secure: true,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags api
         * @name ApiOrganizationBranchUpdate
         * @request PUT:/api/organization-branch/{branchId}/
         * @secure
         */
        apiOrganizationBranchUpdate: (branchId: number, data: OrganizationBranchRequest, params: RequestParams = {}) =>
            this.http.request<OrganizationBranch, any>({
                path: `/api/organization-branch/${branchId}/`,
                method: 'PUT',
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags api
         * @name ApiOrganizationCreate
         * @request POST:/api/organization/
         * @secure
         */
        apiOrganizationCreate: (data: OrganizationRequest, params: RequestParams = {}) =>
            this.http.request<Organization, any>({
                path: `/api/organization/`,
                method: 'POST',
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags api
         * @name ApiOrganizationDestroy
         * @request DELETE:/api/organization/{id}/
         * @secure
         */
        apiOrganizationDestroy: (id: number, params: RequestParams = {}) =>
            this.http.request<void, any>({
                path: `/api/organization/${id}/`,
                method: 'DELETE',
                secure: true,
                ...params,
            }),

        /**
         * No description
         *
         * @tags api
         * @name ApiOrganizationList
         * @request GET:/api/organization/
         * @secure
         */
        apiOrganizationList: (query: ApiOrganizationListParams, params: RequestParams = {}) =>
            this.http.request<PaginatedOrganizationList, any>({
                path: `/api/organization/`,
                method: 'GET',
                query: query,
                secure: true,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags api
         * @name ApiOrganizationPartialUpdate
         * @request PATCH:/api/organization/{id}/
         * @secure
         */
        apiOrganizationPartialUpdate: (id: number, data: PatchedOrganizationRequest, params: RequestParams = {}) =>
            this.http.request<Organization, any>({
                path: `/api/organization/${id}/`,
                method: 'PATCH',
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags api
         * @name ApiOrganizationRetrieve
         * @request GET:/api/organization/{id}/
         * @secure
         */
        apiOrganizationRetrieve: (id: number, params: RequestParams = {}) =>
            this.http.request<Organization, any>({
                path: `/api/organization/${id}/`,
                method: 'GET',
                secure: true,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags api
         * @name ApiOrganizationTypesList
         * @request GET:/api/organization-types/
         * @secure
         */
        apiOrganizationTypesList: (query: ApiOrganizationTypesListParams, params: RequestParams = {}) =>
            this.http.request<PaginatedComplexTypeList, any>({
                path: `/api/organization-types/`,
                method: 'GET',
                query: query,
                secure: true,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags api
         * @name ApiOrganizationTypesRetrieve
         * @request GET:/api/organization-types/{id}/
         * @secure
         */
        apiOrganizationTypesRetrieve: (id: number, params: RequestParams = {}) =>
            this.http.request<ComplexType, any>({
                path: `/api/organization-types/${id}/`,
                method: 'GET',
                secure: true,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags api
         * @name ApiOrganizationUpdate
         * @request PUT:/api/organization/{id}/
         * @secure
         */
        apiOrganizationUpdate: (id: number, data: OrganizationRequest, params: RequestParams = {}) =>
            this.http.request<Organization, any>({
                path: `/api/organization/${id}/`,
                method: 'PUT',
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags api
         * @name ApiPlansList
         * @request GET:/api/plans/
         * @secure
         */
        apiPlansList: (query: ApiPlansListParams, params: RequestParams = {}) =>
            this.http.request<PaginatedPlanList, any>({
                path: `/api/plans/`,
                method: 'GET',
                query: query,
                secure: true,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags api
         * @name ApiPlansRetrieve
         * @request GET:/api/plans/{id}/
         * @secure
         */
        apiPlansRetrieve: (id: number, params: RequestParams = {}) =>
            this.http.request<Plan, any>({
                path: `/api/plans/${id}/`,
                method: 'GET',
                secure: true,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags api
         * @name ApiPoolCreate
         * @request POST:/api/pool/
         * @secure
         */
        apiPoolCreate: (data: PoolRequest, params: RequestParams = {}) =>
            this.http.request<Pool, any>({
                path: `/api/pool/`,
                method: 'POST',
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags api
         * @name ApiPoolDestroy
         * @request DELETE:/api/pool/{id}/
         * @secure
         */
        apiPoolDestroy: (id: number, params: RequestParams = {}) =>
            this.http.request<void, any>({
                path: `/api/pool/${id}/`,
                method: 'DELETE',
                secure: true,
                ...params,
            }),

        /**
         * No description
         *
         * @tags api
         * @name ApiPoolList
         * @request GET:/api/pool/
         * @secure
         */
        apiPoolList: (query: ApiPoolListParams, params: RequestParams = {}) =>
            this.http.request<PaginatedPoolList, any>({
                path: `/api/pool/`,
                method: 'GET',
                query: query,
                secure: true,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags api
         * @name ApiPoolLogReportAttachmentCreate
         * @request POST:/api/pool-log-report-attachment/
         * @secure
         */
        apiPoolLogReportAttachmentCreate: (data: PoolLogReportAttachmentRequest, params: RequestParams = {}) =>
            this.http.request<PoolLogReportAttachment, any>({
                path: `/api/pool-log-report-attachment/`,
                method: 'POST',
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags api
         * @name ApiPoolLogReportAttachmentDestroy
         * @request DELETE:/api/pool-log-report-attachment/{id}/
         * @secure
         */
        apiPoolLogReportAttachmentDestroy: (id: number, params: RequestParams = {}) =>
            this.http.request<void, any>({
                path: `/api/pool-log-report-attachment/${id}/`,
                method: 'DELETE',
                secure: true,
                ...params,
            }),

        /**
         * No description
         *
         * @tags api
         * @name ApiPoolLogReportAttachmentList
         * @request GET:/api/pool-log-report-attachment/
         * @secure
         */
        apiPoolLogReportAttachmentList: (query: ApiPoolLogReportAttachmentListParams, params: RequestParams = {}) =>
            this.http.request<PaginatedPoolLogReportAttachmentList, any>({
                path: `/api/pool-log-report-attachment/`,
                method: 'GET',
                query: query,
                secure: true,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags api
         * @name ApiPoolLogReportAttachmentPartialUpdate
         * @request PATCH:/api/pool-log-report-attachment/{id}/
         * @secure
         */
        apiPoolLogReportAttachmentPartialUpdate: (
            id: number,
            data: PatchedPoolLogReportAttachmentRequest,
            params: RequestParams = {}
        ) =>
            this.http.request<PoolLogReportAttachment, any>({
                path: `/api/pool-log-report-attachment/${id}/`,
                method: 'PATCH',
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags api
         * @name ApiPoolLogReportAttachmentRetrieve
         * @request GET:/api/pool-log-report-attachment/{id}/
         * @secure
         */
        apiPoolLogReportAttachmentRetrieve: (id: number, params: RequestParams = {}) =>
            this.http.request<PoolLogReportAttachment, any>({
                path: `/api/pool-log-report-attachment/${id}/`,
                method: 'GET',
                secure: true,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags api
         * @name ApiPoolLogReportAttachmentUpdate
         * @request PUT:/api/pool-log-report-attachment/{id}/
         * @secure
         */
        apiPoolLogReportAttachmentUpdate: (
            id: number,
            data: PoolLogReportAttachmentRequest,
            params: RequestParams = {}
        ) =>
            this.http.request<PoolLogReportAttachment, any>({
                path: `/api/pool-log-report-attachment/${id}/`,
                method: 'PUT',
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * @description Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed.. As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
         *
         * @tags api
         * @name ApiPoolLogReportCreate
         * @request POST:/api/pool-log-report/
         * @secure
         */
        apiPoolLogReportCreate: (data: PoolLogReportRequest, params: RequestParams = {}) =>
            this.http.request<PoolLogReport, any>({
                path: `/api/pool-log-report/`,
                method: 'POST',
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * @description Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed.. As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
         *
         * @tags api
         * @name ApiPoolLogReportDestroy
         * @request DELETE:/api/pool-log-report/{id}/
         * @secure
         */
        apiPoolLogReportDestroy: (id: number, params: RequestParams = {}) =>
            this.http.request<void, any>({
                path: `/api/pool-log-report/${id}/`,
                method: 'DELETE',
                secure: true,
                ...params,
            }),

        /**
         * @description Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed.. As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
         *
         * @tags api
         * @name ApiPoolLogReportList
         * @request GET:/api/pool-log-report/
         * @secure
         */
        apiPoolLogReportList: (query: ApiPoolLogReportListParams, params: RequestParams = {}) =>
            this.http.request<PaginatedPoolLogReportList, any>({
                path: `/api/pool-log-report/`,
                method: 'GET',
                query: query,
                secure: true,
                format: 'json',
                ...params,
            }),

        /**
         * @description Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed.. As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
         *
         * @tags api
         * @name ApiPoolLogReportPartialUpdate
         * @request PATCH:/api/pool-log-report/{id}/
         * @secure
         */
        apiPoolLogReportPartialUpdate: (id: number, data: PatchedPoolLogReportRequest, params: RequestParams = {}) =>
            this.http.request<PoolLogReport, any>({
                path: `/api/pool-log-report/${id}/`,
                method: 'PATCH',
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * @description Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed.. As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
         *
         * @tags api
         * @name ApiPoolLogReportRetrieve
         * @request GET:/api/pool-log-report/{id}/
         * @secure
         */
        apiPoolLogReportRetrieve: (id: number, params: RequestParams = {}) =>
            this.http.request<PoolLogReport, any>({
                path: `/api/pool-log-report/${id}/`,
                method: 'GET',
                secure: true,
                format: 'json',
                ...params,
            }),

        /**
         * @description Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed.. As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
         *
         * @tags api
         * @name ApiPoolLogReportUpdate
         * @request PUT:/api/pool-log-report/{id}/
         * @secure
         */
        apiPoolLogReportUpdate: (id: number, data: PoolLogReportRequest, params: RequestParams = {}) =>
            this.http.request<PoolLogReport, any>({
                path: `/api/pool-log-report/${id}/`,
                method: 'PUT',
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags api
         * @name ApiPoolPartialUpdate
         * @request PATCH:/api/pool/{id}/
         * @secure
         */
        apiPoolPartialUpdate: (id: number, data: PatchedPoolRequest, params: RequestParams = {}) =>
            this.http.request<Pool, any>({
                path: `/api/pool/${id}/`,
                method: 'PATCH',
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags api
         * @name ApiPoolRenderPdfReportRetrieve
         * @request GET:/api/pool/{id}/render-pdf-report/{date}/{language}/
         * @secure
         */
        apiPoolRenderPdfReportRetrieve: (date: string, id: number, language: string, params: RequestParams = {}) =>
            this.http.request<Pool, any>({
                path: `/api/pool/${id}/render-pdf-report/${date}/${language}/`,
                method: 'GET',
                secure: true,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags api
         * @name ApiPoolRetrieve
         * @request GET:/api/pool/{id}/
         * @secure
         */
        apiPoolRetrieve: (id: number, params: RequestParams = {}) =>
            this.http.request<Pool, any>({
                path: `/api/pool/${id}/`,
                method: 'GET',
                secure: true,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags api
         * @name ApiPoolTypesCreate
         * @request POST:/api/pool-types/
         * @secure
         */
        apiPoolTypesCreate: (data: PoolTypeRequest, params: RequestParams = {}) =>
            this.http.request<PoolType, any>({
                path: `/api/pool-types/`,
                method: 'POST',
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags api
         * @name ApiPoolTypesDestroy
         * @request DELETE:/api/pool-types/{id}/
         * @secure
         */
        apiPoolTypesDestroy: (id: number, params: RequestParams = {}) =>
            this.http.request<void, any>({
                path: `/api/pool-types/${id}/`,
                method: 'DELETE',
                secure: true,
                ...params,
            }),

        /**
         * No description
         *
         * @tags api
         * @name ApiPoolTypesList
         * @request GET:/api/pool-types/
         * @secure
         */
        apiPoolTypesList: (query: ApiPoolTypesListParams, params: RequestParams = {}) =>
            this.http.request<PaginatedPoolTypeList, any>({
                path: `/api/pool-types/`,
                method: 'GET',
                query: query,
                secure: true,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags api
         * @name ApiPoolTypesPartialUpdate
         * @request PATCH:/api/pool-types/{id}/
         * @secure
         */
        apiPoolTypesPartialUpdate: (id: number, data: PatchedPoolTypeRequest, params: RequestParams = {}) =>
            this.http.request<PoolType, any>({
                path: `/api/pool-types/${id}/`,
                method: 'PATCH',
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags api
         * @name ApiPoolTypesRetrieve
         * @request GET:/api/pool-types/{id}/
         * @secure
         */
        apiPoolTypesRetrieve: (id: number, params: RequestParams = {}) =>
            this.http.request<PoolType, any>({
                path: `/api/pool-types/${id}/`,
                method: 'GET',
                secure: true,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags api
         * @name ApiPoolTypesUpdate
         * @request PUT:/api/pool-types/{id}/
         * @secure
         */
        apiPoolTypesUpdate: (id: number, data: PoolTypeRequest, params: RequestParams = {}) =>
            this.http.request<PoolType, any>({
                path: `/api/pool-types/${id}/`,
                method: 'PUT',
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags api
         * @name ApiPoolUpdate
         * @request PUT:/api/pool/{id}/
         * @secure
         */
        apiPoolUpdate: (id: number, data: PoolRequest, params: RequestParams = {}) =>
            this.http.request<Pool, any>({
                path: `/api/pool/${id}/`,
                method: 'PUT',
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * @description OpenApi3 schema for this API. Format can be selected via content negotiation. - YAML: application/vnd.oai.openapi - JSON: application/vnd.oai.openapi+json
         *
         * @tags api
         * @name ApiSchemaRetrieve
         * @request GET:/api/schema/
         * @secure
         */
        apiSchemaRetrieve: (query: ApiSchemaRetrieveParams, params: RequestParams = {}) =>
            this.http.request<Record<string, any>, any>({
                path: `/api/schema/`,
                method: 'GET',
                query: query,
                secure: true,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags api
         * @name ApiSubscribeMasavCreate
         * @request POST:/api/subscribe-masav
         * @secure
         */
        apiSubscribeMasavCreate: (data: PaymentLinkRequestRequest, params: RequestParams = {}) =>
            this.http.request<void, any>({
                path: `/api/subscribe-masav`,
                method: 'POST',
                body: data,
                secure: true,
                type: ContentType.Json,
                ...params,
            }),

        /**
         * No description
         *
         * @tags api
         * @name ApiSubscribeNotifyCreate
         * @request POST:/api/subscribe-notify
         * @secure
         */
        apiSubscribeNotifyCreate: (params: RequestParams = {}) =>
            this.http.request<void, any>({
                path: `/api/subscribe-notify`,
                method: 'POST',
                secure: true,
                ...params,
            }),

        /**
         * @description Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed.. As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
         *
         * @tags api
         * @name ApiSubscriptionCreate
         * @request POST:/api/subscription/
         * @secure
         */
        apiSubscriptionCreate: (data: SubscriptionRequest, params: RequestParams = {}) =>
            this.http.request<Subscription, any>({
                path: `/api/subscription/`,
                method: 'POST',
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * @description Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed.. As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
         *
         * @tags api
         * @name ApiSubscriptionDestroy
         * @request DELETE:/api/subscription/{id}/
         * @secure
         */
        apiSubscriptionDestroy: (id: number, params: RequestParams = {}) =>
            this.http.request<void, any>({
                path: `/api/subscription/${id}/`,
                method: 'DELETE',
                secure: true,
                ...params,
            }),

        /**
         * @description Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed.. As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
         *
         * @tags api
         * @name ApiSubscriptionList
         * @request GET:/api/subscription/
         * @secure
         */
        apiSubscriptionList: (query: ApiSubscriptionListParams, params: RequestParams = {}) =>
            this.http.request<PaginatedSubscriptionList, any>({
                path: `/api/subscription/`,
                method: 'GET',
                query: query,
                secure: true,
                format: 'json',
                ...params,
            }),

        /**
         * @description Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed.. As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
         *
         * @tags api
         * @name ApiSubscriptionPartialUpdate
         * @request PATCH:/api/subscription/{id}/
         * @secure
         */
        apiSubscriptionPartialUpdate: (id: number, data: PatchedSubscriptionRequest, params: RequestParams = {}) =>
            this.http.request<Subscription, any>({
                path: `/api/subscription/${id}/`,
                method: 'PATCH',
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * @description Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed.. As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
         *
         * @tags api
         * @name ApiSubscriptionRetrieve
         * @request GET:/api/subscription/{id}/
         * @secure
         */
        apiSubscriptionRetrieve: (id: number, params: RequestParams = {}) =>
            this.http.request<Subscription, any>({
                path: `/api/subscription/${id}/`,
                method: 'GET',
                secure: true,
                format: 'json',
                ...params,
            }),

        /**
         * @description Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed.. As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
         *
         * @tags api
         * @name ApiSubscriptionUpdate
         * @request PUT:/api/subscription/{id}/
         * @secure
         */
        apiSubscriptionUpdate: (id: number, data: SubscriptionRequest, params: RequestParams = {}) =>
            this.http.request<Subscription, any>({
                path: `/api/subscription/${id}/`,
                method: 'PUT',
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags api
         * @name ApiTeardowne2ECreate
         * @request POST:/api/teardowne2e
         * @secure
         */
        apiTeardowne2ECreate: (params: RequestParams = {}) =>
            this.http.request<void, any>({
                path: `/api/teardowne2e`,
                method: 'POST',
                secure: true,
                ...params,
            }),

        /**
         * @description Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed.. As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
         *
         * @tags api
         * @name ApiToxicReportCreate
         * @request POST:/api/toxic-report/
         * @secure
         */
        apiToxicReportCreate: (data: ToxicReportRequest, params: RequestParams = {}) =>
            this.http.request<ToxicReport, any>({
                path: `/api/toxic-report/`,
                method: 'POST',
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * @description Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed.. As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
         *
         * @tags api
         * @name ApiToxicReportList
         * @request GET:/api/toxic-report/
         * @secure
         */
        apiToxicReportList: (query: ApiToxicReportListParams, params: RequestParams = {}) =>
            this.http.request<PaginatedToxicReportList, any>({
                path: `/api/toxic-report/`,
                method: 'GET',
                query: query,
                secure: true,
                format: 'json',
                ...params,
            }),

        /**
         * @description Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed.. As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
         *
         * @tags api
         * @name ApiToxicReportMonthlyReportsByYearRetrieve
         * @request GET:/api/toxic-report/monthly_reports_by_year/
         * @secure
         */
        apiToxicReportMonthlyReportsByYearRetrieve: (
            query: ApiToxicReportMonthlyReportsByYearRetrieveParams,
            params: RequestParams = {}
        ) =>
            this.http.request<ToxicReport, any>({
                path: `/api/toxic-report/monthly_reports_by_year/`,
                method: 'GET',
                query: query,
                secure: true,
                format: 'json',
                ...params,
            }),

        /**
         * @description Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed.. As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
         *
         * @tags api
         * @name ApiToxicReportRenderPdfReportRetrieve
         * @request GET:/api/toxic-report/{id}/render-pdf-report/{date}/{language}/
         * @secure
         */
        apiToxicReportRenderPdfReportRetrieve: (
            { date, id, language, ...query }: ApiToxicReportRenderPdfReportRetrieveParams,
            params: RequestParams = {}
        ) =>
            this.http.request<ToxicReport, any>({
                path: `/api/toxic-report/${id}/render-pdf-report/${date}/${language}/`,
                method: 'GET',
                query: query,
                secure: true,
                format: 'json',
                ...params,
            }),

        /**
         * @description Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed.. As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
         *
         * @tags api
         * @name ApiToxicReportReportsByMonthRetrieve
         * @request GET:/api/toxic-report/reports_by_month/
         * @secure
         */
        apiToxicReportReportsByMonthRetrieve: (
            query: ApiToxicReportReportsByMonthRetrieveParams,
            params: RequestParams = {}
        ) =>
            this.http.request<ToxicReport, any>({
                path: `/api/toxic-report/reports_by_month/`,
                method: 'GET',
                query: query,
                secure: true,
                format: 'json',
                ...params,
            }),

        /**
         * @description Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed.. As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
         *
         * @tags api
         * @name ApiToxicReportRetrieve
         * @request GET:/api/toxic-report/{id}/
         * @secure
         */
        apiToxicReportRetrieve: (id: number, params: RequestParams = {}) =>
            this.http.request<ToxicReport, any>({
                path: `/api/toxic-report/${id}/`,
                method: 'GET',
                secure: true,
                format: 'json',
                ...params,
            }),

        /**
         * @description Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed.. As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
         *
         * @tags api
         * @name ApiToxicReportUpdate
         * @request PUT:/api/toxic-report/{id}/
         * @secure
         */
        apiToxicReportUpdate: (id: number, data: ToxicReportRequest, params: RequestParams = {}) =>
            this.http.request<ToxicReport, any>({
                path: `/api/toxic-report/${id}/`,
                method: 'PUT',
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags api
         * @name ApiToxicSubstanceList
         * @request GET:/api/toxic-substance/
         * @secure
         */
        apiToxicSubstanceList: (query: ApiToxicSubstanceListParams, params: RequestParams = {}) =>
            this.http.request<PaginatedToxicSubstanceList, any>({
                path: `/api/toxic-substance/`,
                method: 'GET',
                query: query,
                secure: true,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags api
         * @name ApiToxicSubstanceRetrieve
         * @request GET:/api/toxic-substance/{id}/
         * @secure
         */
        apiToxicSubstanceRetrieve: (id: number, params: RequestParams = {}) =>
            this.http.request<ToxicSubstance, any>({
                path: `/api/toxic-substance/${id}/`,
                method: 'GET',
                secure: true,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags api
         * @name ApiUsersCreate
         * @request POST:/api/users/
         * @secure
         */
        apiUsersCreate: (data: UserCreateRequest, params: RequestParams = {}) =>
            this.http.request<UserCreate, any>({
                path: `/api/users/`,
                method: 'POST',
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags api
         * @name ApiUsersDestroy
         * @request DELETE:/api/users/{id}/
         * @secure
         */
        apiUsersDestroy: (id: number, params: RequestParams = {}) =>
            this.http.request<void, any>({
                path: `/api/users/${id}/`,
                method: 'DELETE',
                secure: true,
                ...params,
            }),

        /**
         * No description
         *
         * @tags api
         * @name ApiUsersList
         * @request GET:/api/users/
         * @secure
         */
        apiUsersList: (query: ApiUsersListParams, params: RequestParams = {}) =>
            this.http.request<PaginatedUserDetailsList, any>({
                path: `/api/users/`,
                method: 'GET',
                query: query,
                secure: true,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags api
         * @name ApiUsersMeRetrieve
         * @request GET:/api/users/me/
         * @secure
         */
        apiUsersMeRetrieve: (params: RequestParams = {}) =>
            this.http.request<UserDetails, any>({
                path: `/api/users/me/`,
                method: 'GET',
                secure: true,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags api
         * @name ApiUsersPartialUpdate
         * @request PATCH:/api/users/{id}/
         * @secure
         */
        apiUsersPartialUpdate: (id: number, data: PatchedUserDetailsRequest, params: RequestParams = {}) =>
            this.http.request<UserDetails, any>({
                path: `/api/users/${id}/`,
                method: 'PATCH',
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags api
         * @name ApiUsersRequestDeleteRetrieve
         * @request GET:/api/users/request_delete/
         * @secure
         */
        apiUsersRequestDeleteRetrieve: (params: RequestParams = {}) =>
            this.http.request<UserDetails, any>({
                path: `/api/users/request_delete/`,
                method: 'GET',
                secure: true,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags api
         * @name ApiUsersRetrieve
         * @request GET:/api/users/{id}/
         * @secure
         */
        apiUsersRetrieve: (id: number, params: RequestParams = {}) =>
            this.http.request<UserDetails, any>({
                path: `/api/users/${id}/`,
                method: 'GET',
                secure: true,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags api
         * @name ApiUsersUpdate
         * @request PUT:/api/users/{id}/
         * @secure
         */
        apiUsersUpdate: (id: number, data: UserDetailsRequest, params: RequestParams = {}) =>
            this.http.request<UserDetails, any>({
                path: `/api/users/${id}/`,
                method: 'PUT',
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),
    }
    login = {
        /**
         * @description login which starts a 2fa flow
         *
         * @tags login
         * @name LoginCreate
         * @request POST:/login
         * @secure
         */
        loginCreate: (data: LoginRequest, params: RequestParams = {}) =>
            this.http.request<void, any>({
                path: `/login`,
                method: 'POST',
                body: data,
                secure: true,
                type: ContentType.Json,
                ...params,
            }),
    }
    twoFa = {
        /**
         * @description Checking the verfication code and the date
         *
         * @tags two-fa
         * @name TwoFaCreate
         * @request POST:/two-fa
         * @secure
         */
        twoFaCreate: (data: TwoFaRequest, params: RequestParams = {}) =>
            this.http.request<TwoFaResponse, any>({
                path: `/two-fa`,
                method: 'POST',
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),
    }
}
