import { Api, ContentType, HttpClient } from '~/generated/api'
import { QueryClient } from '@tanstack/react-query'
import { globalState } from './state'
import { t } from 'i18next'
import { toast } from 'react-toastify'
import { captureException } from '@sentry/remix'

const handleNetworkError = () => ({
    data: {
        error: {
            data: { server: t`Network Error` },
            status: 500,
            statusText: 'Network Error',
        },
    },
})

const handleServerError = (response?: any) => ({
    data: {
        error: {
            data: { server: t`Server Error` },
            status: response?.status || 500,
            statusText: response?.statusText || 'Server Error',
        },
    },
})

export let online = true
export function serializeParams(params: Record<string, string>) {
    const queryString = []

    for (const key in params) {
        if (params.hasOwnProperty(key)) {
            const value = params[key]

            if (Array.isArray(value)) {
                value.forEach((item) => {
                    queryString.push(
                        `${encodeURIComponent(key)}=${encodeURIComponent(item)}`
                    )
                })
            } else {
                queryString.push(
                    `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
                )
            }
        }
    }

    return `${queryString.join('&')}`
}

export function apiClientFactory() {
    const { accessToken } = globalState.getState()

    const headers: HeadersInit = {}

    if (accessToken) {
        headers['Authorization'] = 'Bearer ' + accessToken
    }

    const httpClient = new HttpClient({
        headers,

        baseURL: import.meta.env.VITE_API_URL,
        paramsSerializer: {
            serialize: (params) => {
                const searchParams = serializeParams(params)
                return searchParams
            },
        },
    })

    httpClient.instance.interceptors.response.use(
        (response) => {
            if (!online) {
                toast.success(t`Internet connection is back!`)
                online = true
            }
            return response
        },
        (error) => {
            const { response } = error

            if (!response && error.code === 'ERR_NETWORK') {
                if (online) {
                    online = false
                    toast.error(
                        t`Network Error - please check your internet connection`
                    )
                }
                return Promise.resolve(handleNetworkError())
            }

            if (!response || response.status === 500) {
                !response && captureException(error)
                return Promise.resolve(handleServerError(response))
            }

            return Promise.resolve({
                data: {
                    error: {
                        data: response.data || response?.message,
                        status: response.status,
                        statusText: response.statusText,
                    },
                },
            })
        }
    )

    return new Api(httpClient)
}

export const CacheKeys = {
    getCountries: 'GET_COUNTRIES',
    getUser: 'GET_USER',
    getBranch: 'GET_BRANCH',
    getOperators: 'GET_OPERATORS',
    getAllPools: 'GET_POOLS',
    getUserDetails: 'GET_USER_DETAILS',
    getManagers: 'GET_MANAGERS',
    getToxicSubstanceList: 'TOXIC_LIST',
} as const

export const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            staleTime: 15000,
        },
    },
})

export const client = apiClientFactory()

export const setCache = (key: string[], value) => {
    localStorage.setItem('cacher__' + key.join(), JSON.stringify(value))
}

export const getCache = (key: string[]) => {
    const res = JSON.parse(
        localStorage.getItem('cacher__' + key.join()) || '{"empty_cache":true}'
    )

    return res
}

export const getFromOutbox = (type: 'toxic report' | 'pool report') => {
    const reports = JSON.parse(localStorage.getItem('outbox__' + type) || '[]')
    return reports.map((r) => JSON.parse(r))
}

export const sendToOutbox = (
    type: 'toxic report' | 'pool report',
    value: any
) => {
    toast.info(t`report will be sent when internet connection is on`)
    const outBox = JSON.parse(localStorage.getItem('outbox__' + type) || '[]')
    outBox.push(value)
    localStorage.setItem('outbox__' + type, JSON.stringify(outBox))
}

export const sendOutbox = async () => {
    const connectionToServer = await client.http.request({
        path: 'health_check',
    })
    if ('error' in connectionToServer) {
        connectionToServer.error.status === 500
        online = false
        console.info('error - server is probably offline? will check later.')
        return connectionToServer
    }

    const toxicReports = JSON.parse(
        localStorage.getItem('outbox__toxic report') || '[]'
    )
    const poolReports = JSON.parse(
        localStorage.getItem('outbox__pool report') || '[]'
    )

    const newToxicReports = []
    for (let toxicReport of toxicReports) {
        toxicReport = JSON.parse(toxicReport)
        const form = new FormData()
        for (const key in toxicReport) {
            form.append(key, toxicReport[key])
        }

        const res = await client.api.apiToxicReportCreate(form, {
            type: ContentType.FormData,
        })
        if (res?.error) {
            toast.error(
                'unable to send report' + JSON.stringify(res.error.data)
            )
            newToxicReports.push(toxicReport)
            return res
        }
    }
    localStorage.setItem(
        'outbox__toxic report',
        JSON.stringify(newToxicReports)
    )

    const newPoolReports = []
    for (let poolReport of poolReports) {
        poolReport = JSON.parse(poolReport)
        const form = new FormData()
        for (const key in poolReport) {
            form.append(key, poolReport[key])
        }
        const res = await client.api.apiPoolLogReportCreate(form, {
            type: ContentType.FormData,
        })
        if (res?.error) {
            console.error('unable to send report!', res.error.data)
            toast.error(
                'unable to send report' + JSON.stringify(res.error.data)
            )
            newPoolReports.push(poolReport)
            return res
        }
    }
    localStorage.setItem('outbox__pool report', JSON.stringify(newToxicReports))
}
